import styled from "styled-components";

const Container = styled.div`
  .organizationImgWrap {
    height: 660px;
  }

  @media (max-width: 1400px) {
    .organizationImgWrap {
      height: calc(100vw * (660 / 1400));
    }
  }

  @media (max-width: 767px) {
    .organizationImgWrap {
      height: calc(100vw * (737 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
