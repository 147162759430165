import { api } from "service";
import { useQuery } from "react-query";

export default function useBoard({ board, detail, group }) {
  const getBoard = useQuery(["board", board], () => api.board.getBoard(board), {
    refetchOnWindowFocus: false,
    enabled: !!board,
  });

  const getBoardDetail = useQuery(
    ["boardDetail", detail],
    () => api.board.getBoardDetail(detail),
    {
      refetchOnWindowFocus: false,
      enabled: !!detail,
    }
  );

  const getBoardGroup = useQuery(
    ["boardGroup"],
    () => api.board.getBoardGroup(),
    {
      refetchOnWindowFocus: false,
      enabled: !!group,
    }
  );

  return {
    board: getBoard.data || {},
    detail: getBoardDetail.data?.data || {},
    group: getBoardGroup.data?.data || [],
  };
}
