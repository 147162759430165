import styled from "styled-components";

const Container = styled.div`
  position: relative;

  & label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 1.4;

    span {
      color: var(--key01);
    }
  }

  & input {
    width: 100%;
    height: 40px;
    padding: 0 14px;
    font-size: 16px;
    font-weight: 500;
    background-color: var(--white);
    border: 1px solid var(--gray01);
    border-radius: 5px;

    &::placeholder {
      color: var(--gray02);
    }
  }

  & .errorText {
    position: absolute;
    top: calc(100% + 5px);
    font-size: 16px;
    font-weight: 500;
    color: var(--error);
  }

  @media (max-width: 1400px) {
    & label {
      margin-bottom: calc(100vw * (5 / 1400));
    }

    & input {
      height: calc(100vw * (40 / 1400));
      padding: 0 calc(100vw * (14 / 1400));
      font-size: calc(100vw * (16 / 1400));
      border-radius: calc(100vw * (5 / 1400));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 1400)));
      font-size: calc(100vw * (16 / 1400));
    }
  }

  @media (max-width: 767px) {
    & label {
      margin-bottom: calc(100vw * (5 / 375));
    }

    & input {
      height: calc(100vw * (40 / 375));
      padding: 0 calc(100vw * (9 / 375));
      font-size: calc(100vw * (14 / 375));
      border-radius: calc(100vw * (5 / 375));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 375)));
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
