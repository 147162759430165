import { useLanguage } from "hooks";
import Styles from "./styles";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PC, Mobile } from "hooks/useMediaQuery";

// images
import Logo from "resources/image/icon/logo.svg";
import LogoWhite from "resources/image/icon/logo_white.svg";
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconClose from "resources/image/icon/icon_close.svg";

function Header({ main }) {
  const location = useLocation();
  const languageInfo = useLanguage();
  const quarterLang = languageInfo.quarterLang;

  const path = location.pathname.split("/")[1];

  const [isScroll, setIsScroll] = useState(0);
  const [isLanguage, setIsLanguage] = useState("KOR");
  const [isMenu, setIsMenu] = useState(false);

  const menuList = [
    {
      title: quarterLang ? "기업소개" : "About Us",
      path: "introduction",
      link: "/introduction/greeting",
    },
    {
      title: quarterLang ? "연구개발" : "R&D",
      path: "research",
      link: "/research/technology",
    },
    {
      title: quarterLang ? "고객지원" : "Service Center",
      link: "/customer-support/announcements",
    },
    {
      title: quarterLang ? "인재채용" : "Careers",
      path: "recruitment",
      link: "/recruitment/talent",
    },
  ];

  const languageList = ["KOR", "ENG"];

  const updateScroll = () => {
    setIsScroll(window.pageYOffset || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, [isScroll]);

  return (
    <>
      <PC>
        <Styles.Container className={isScroll === 0 && main ? "main" : ""}>
          <div className="inner">
            <h1>
              <Link to={"/"} className="logo">
                <img
                  src={isScroll === 0 && main ? LogoWhite : Logo}
                  alt="logo"
                />
              </Link>
            </h1>

            <ul className="menuListWrap">
              {menuList.map((item, index) => (
                <li className="menuList" key={"menuList" + index}>
                  <Link
                    to={item.link}
                    className={path === item.path ? "on" : ""}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="languageListWrap">
              {languageList.map((item, index) => (
                <button
                  type="button"
                  key={"language" + index}
                  className={
                    "languageButton" +
                    (sessionStorage.getItem("language") === item ? " on" : "")
                  }
                  onClick={() => {
                    languageInfo.setLanguage(item);
                    window.location.reload();
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </Styles.Container>
      </PC>

      <Mobile>
        <Styles.MobileContainer>
          <div className="inner">
            <h1>
              <Link to={"/"} className="logo">
                <img src={Logo} alt="logo" />
              </Link>
            </h1>

            <button
              type="button"
              className="menuButton"
              onClick={() => setIsMenu(true)}
            >
              <img src={IconMenu} alt="menu" />
            </button>
          </div>
        </Styles.MobileContainer>

        {isMenu && (
          <Styles.Nav>
            <div className="navTop">
              <div className="inner">
                <div className="languageListWrap">
                  {languageList.map((item, index) => (
                    <button
                      type="button"
                      key={"language" + index}
                      className={
                        "languageButton" +
                        (sessionStorage.getItem("language") === item
                          ? " on"
                          : "")
                      }
                      onClick={() => {
                        languageInfo.setLanguage(item);
                        window.location.reload();
                      }}
                    >
                      {item}
                    </button>
                  ))}
                </div>

                <button
                  type="button"
                  className="closeButton"
                  onClick={() => setIsMenu(false)}
                >
                  <img src={IconClose} alt="close" />
                </button>
              </div>
            </div>

            <div className="menuWrap">
              <div className="inner">
                <ul className="menuListWrap">
                  {menuList.map((item, index) => (
                    <li className="menuList" key={"menuList" + index}>
                      <Link
                        to={item.link}
                        className={path === item.path ? "on" : ""}
                        onClick={() => setIsMenu(false)}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Styles.Nav>
        )}
      </Mobile>
    </>
  );
}

export default Header;
