import styled from "styled-components";

const Container = styled.div`
  background: no-repeat right center / cover;

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    color: var(--white);
  }

  .bannerTitle {
    margin-bottom: 10px;
    font-size: 38px;
    font-weight: 600;
  }

  .bannerDescription {
    line-height: 1.4;
  }

  @media (max-width: 1400px) {
    .inner {
      height: calc(100vw * (200 / 1400));
    }

    .bannerTitle {
      margin-bottom: calc(100vw * (10 / 1400));
      font-size: calc(100vw * (38 / 1400));
    }
  }

  @media (max-width: 767px) {
    .inner {
      height: calc(100vw * (95 / 375));
    }

    .bannerTitle {
      margin-bottom: 0;
      font-size: calc(100vw * (24 / 375));
    }

    .bannerDescription {
      display: none;
    }
  }
`;

export { Container };

export default { Container };
