import utils from "utils";
import Styles from "./styles";
import { ui } from "components";
import { recruitmentList } from "service/api/dummy";
import { useBoard, useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";

function RecruitmentDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { detail } = useBoard({ detail: id });
  const { suffix } = useLanguage();

  return (
    <Styles.Container className="articleWrap">
      <article className="recruitmentDetailArticle">
        <div className="inner">
          {detail.hold_yn === "Y" && <div className="open">Open</div>}

          <p className="title">{detail["title" + suffix]}</p>

          <p className="date">
            Date : {utils.date.getDateType(detail.created_at?.split(" ")[0])}
          </p>

          {detail.file_list?.length > 0 && (
            <ul className="fileList">
              {detail.file_list?.map((item, index) => (
                <li className="fileItem" key={"file" + index}>
                  <div className="attached">Attached</div>

                  <a href={item.api_uri_path} download rel="noreferrer">
                    {item.hashed_name}
                  </a>
                </li>
              ))}
            </ul>
          )}

          {/* 내부 style 지우고 사용 */}
          <div
            className="editorWrap ck-content"
            dangerouslySetInnerHTML={{
              __html: detail["description" + suffix],
            }}
          ></div>

          <div className="listButton">
            <ui.button.BasicButton title="List" onClick={() => navigate(-1)} />
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default RecruitmentDetail;
