import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { useLanguage } from "hooks";
import { common, layout } from "components";

// images
import BgBanner from "resources/image/introduction/bg_banner.jpg";
import BgBannerMb from "resources/image/introduction/bg_banner_mb.jpg";

function IntroductionPage() {
  const { quarterLang } = useLanguage();

  const tabList = [
    {
      title: quarterLang ? "인사말" : "CEO Message",
      link: "/introduction/greeting",
    },
    { title: quarterLang ? "연혁" : "History", link: "/introduction/history" },
    {
      title: quarterLang ? "관계사 현황" : "Relation",
      link: "/introduction/affiliates",
    },
    {
      title: quarterLang ? "조직도" : "Organization",
      link: "/introduction/organization",
    },
    {
      title: quarterLang ? "사업영역" : "Business Areas",
      link: "/introduction/business",
    },
    {
      title: quarterLang ? "Business Partner" : "Business Partner",
      link: "/introduction/partner",
    },
    {
      title: quarterLang ? "오시는 길" : "Contact Us",
      link: "/introduction/directions",
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.MainBanner
          img={BgBanner}
          mbImg={BgBannerMb}
          title={quarterLang ? "기업소개" : "About Us"}
          description={
            quarterLang ? (
              <>
                주식회사 에이바이오텍을 방문해 주셔서 감사합니다. <br />
                독자적인 인간 항체 라이브러리 기술을 이용하여 혁신 의약품을
                개발하고 있습니다.
              </>
            ) : (
              <>
                Thank you for visiting ABioTech Co., Ltd.
                <br />
                We are developing innovative pharmaceuticals using our
                proprietary synthetic antibody library technology.
              </>
            )
          }
        />
        <layout.Navigation tabList={tabList} />
        <Outlet />
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default IntroductionPage;
