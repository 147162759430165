import Styles from "./styles";
import ArrowRight from "resources/image/icon/icon_arrow_right_long_white.svg";
import utils from "utils";
import { Link } from "react-router-dom";
import { IMAGEPATH } from "service/connector";

function NewsList({ data, suffix, big }) {
  const { id, thumbnail, created_at, image } = data;

  return (
    <Styles.Container>
      <Link
        className={`${big ? "big" : ""}`}
        to={`/customer-support/announcements/detail/${id}`}
      >
        <div className="hover">
          View More
          <div className="arrowIcon">
            <img src={ArrowRight} alt="View More" />
          </div>
        </div>

        <div className="img">
          <img
            src={IMAGEPATH + image}
            alt={data["title" + suffix]}
            style={{ backgroundColor: "#EFEFEF" }}
          />
        </div>
        <p className="title">{data["title" + suffix]}</p>
        <p className="date">
          {utils.date.getDateType(created_at.split(" ")[0])}
        </p>
      </Link>
    </Styles.Container>
  );
}

export default NewsList;
