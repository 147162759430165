import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import IntroductionPage from "./IntroductionPage";
import ResearchPage from "./ResearchPage";
import CustomerSupportPage from "./CustomerSupportPage";
import RecruitmentPage from "./RecruitmentPage";
import TermsPage from "./TermsPage";

export default {
  ModalPage,
  MainPage,
  IntroductionPage,
  ResearchPage,
  CustomerSupportPage,
  RecruitmentPage,
  TermsPage,
};

export {
  ModalPage,
  MainPage,
  IntroductionPage,
  ResearchPage,
  CustomerSupportPage,
  RecruitmentPage,
  TermsPage,
};
