import Styles from "./styles";
import { useBoard, useLanguage } from "hooks";

function History() {
  const { quarterLang, suffix } = useLanguage();
  const { group } = useBoard({ group: true });

  return (
    <Styles.Container className="articleWrap">
      <article className="historyArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">History</div>
                <h2 className="articleTitle">연혁</h2>
              </>
            ) : (
              <h2 className="articleTitle">Corporate History</h2>
            )}
          </div>

          <div className="historyListWrap">
            <p className="historyText">~Present</p>

            <div className="list">
              {group?.map((item, index) => (
                <div key={`historyItem${index}`} className="historyItem">
                  <div className="date">{item["title" + suffix]}</div>
                  <ul className="listWrap">
                    {item?.board_list?.map((listItem, listIndex) => (
                      <li key={listIndex}>{listItem["subtitle" + suffix]}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            <p className="historyText">ABioTech Legacy</p>
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default History;
