import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import Styles from "./styles";

// images
import ImgIntroduction01 from "resources/image/main/img_introduction01.jpg";
import ImgIntroduction02 from "resources/image/main/img_introduction02.jpg";
import ImgIntroduction03 from "resources/image/main/img_introduction03.jpg";
import ImgServiceBranch01 from "resources/image/introduction/img_service_branch01.png";
import ImgServiceBranch01Mb from "resources/image/introduction/img_service_branch01_mb.png";
import ImgServiceBranch01En from "resources/image/introduction/img_service_branch01_en.png";
import ImgServiceBranch01EnMb from "resources/image/introduction/img_service_branch01_en_mb.png";
import ImgServiceBranch02 from "resources/image/introduction/img_service_branch02.png";
import ImgServiceBranch02Mb from "resources/image/introduction/img_service_branch02_mb.png";
import ImgServiceBranch02En from "resources/image/introduction/img_service_branch02_en.png";
import ImgServiceBranch02EnMb from "resources/image/introduction/img_service_branch02_en_mb.png";

function Business() {
  const { quarterLang } = useLanguage();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const introductionList = [
    {
      img: ImgIntroduction01,
      title: quarterLang ? (
        "국제 특허 및 기술 특허"
      ) : (
        <>
          International Patents
          <br /> and Technology Licenses
        </>
      ),
      description: quarterLang ? (
        <>
          국제 특허 및 일본 우수 석학의 기술 특허 계약을
          <br /> 보유하고 있습니다.
        </>
      ) : (
        <>
          Holding international patents
          <br /> and technology licenses from
          <br /> distinguished Japanese scholars.
        </>
      ),
    },
    {
      img: ImgIntroduction02,
      title: quarterLang ? (
        "항체 라이브러리 자체 제작"
      ) : (
        <>
          In-House Antibody
          <br /> Library Development
        </>
      ),
      description: quarterLang ? (
        <>
          다양한 항원에 대응 가능한 항체 라이브러리를 <br />
          제작 및 보유하고 있습니다.
        </>
      ) : (
        <>
          Creating and maintaining antibody
          <br /> libraries capable of responding
          <br /> to diverse antigens.
        </>
      ),
    },
    {
      img: ImgIntroduction03,
      title: quarterLang ? (
        "최신 의약품의 세계화 선도"
      ) : (
        <>
          Pioneering the Globalization
          <br /> of advanced Pharmaceuticals
        </>
      ),
      description: quarterLang ? (
        <>
          혁신적인 신약 후보물질을 개발하고 있어, <br />
          최신 의약품의 세계화를 선도합니다.
        </>
      ) : (
        <>
          Leading the globalization of advanced
          <br /> pharmaceuticals through innovative
          <br /> drug candidate development.
        </>
      ),
    },
  ];

  return (
    <Styles.Container className="articleWrap">
      <article className="divisionArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Core Competencies</div>
                <h2 className="articleTitle">사업 강점</h2>
              </>
            ) : (
              <h2 className="articleTitle">Core Competencies</h2>
            )}
          </div>

          <ul className="introductionList">
            {introductionList.map((item, index) => (
              <li key={"introduction" + index}>
                <div className="img">
                  <img src={item.img} alt={item.title} />
                </div>
                <p className="title">{item.title}</p>
                <p className="description">{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </article>

      <article className="branchArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Business Fields</div>
                <h2 className="articleTitle">사업 영역</h2>
              </>
            ) : (
              <h2 className="articleTitle">Business Fields</h2>
            )}
          </div>

          <ul className="serviceBranchList">
            <li className="serviceBranchItem">
              <img
                src={
                  quarterLang
                    ? isMobile
                      ? ImgServiceBranch01Mb
                      : ImgServiceBranch01
                    : isMobile
                    ? ImgServiceBranch01EnMb
                    : ImgServiceBranch01En
                }
                alt={quarterLang ? "사업 영역01" : "Business Fields01"}
              />
            </li>

            <li className="serviceBranchItem">
              <img
                src={
                  quarterLang
                    ? isMobile
                      ? ImgServiceBranch02Mb
                      : ImgServiceBranch02
                    : isMobile
                    ? ImgServiceBranch02EnMb
                    : ImgServiceBranch02En
                }
                alt={quarterLang ? "사업 영역02" : "Business Fields02"}
              />
            </li>
          </ul>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Business;
