import styled from "styled-components";

const Container = styled.div`
  .partnerImgWrap {
    height: 420px;
  }

  @media (max-width: 1400px) {
    .partnerImgWrap {
      height: calc(100vw * (420 / 1400));
    }
  }

  @media (max-width: 767px) {
    .partnerImgWrap {
      height: calc(100vw * (397 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
