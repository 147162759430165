import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { useLanguage } from "hooks";
import { useLocation } from "react-router-dom";
import { common, layout } from "components";

// images
import BgBanner from "resources/image/customer-support/bg_banner.jpg";
import BgBannerMb from "resources/image/customer-support/bg_banner_mb.jpg";
function CustomerSupportPage() {
  const location = useLocation();
  const pathname = location.pathname;
  const { quarterLang } = useLanguage();

  const path = pathname.split("/")[3];

  const tabList = [
    {
      title: quarterLang ? "공지사항" : "Notification",
      link: "/customer-support/announcements",
    },
    {
      title: quarterLang ? "고객문의" : "Contact Us",
      link: "/customer-support/contact",
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.MainBanner
          img={BgBanner}
          mbImg={BgBannerMb}
          title={quarterLang ? "고객지원" : "Service Center"}
          description={
            quarterLang ? (
              <>
                주식회사 에이바이오텍을 방문해 주셔서 감사합니다. <br />
                고객과 함께 신약 개발 혁신을 이뤄나가겠습니다.
              </>
            ) : (
              <>
                Thank you for visiting ABioTech Co., Ltd.
                <br />
                Together with our customers, we will achieve innovation in drug
                development.
              </>
            )
          }
        />

        {path !== "detail" && <layout.Navigation tabList={tabList} />}
        <Outlet />
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default CustomerSupportPage;
