import utils from "utils";
import Styles from "./styles";
import { ui } from "components";
import { Link } from "react-router-dom";
import { useBoard, useLanguage } from "hooks";

function RecruitmentList() {
  const { quarterLang, suffix } = useLanguage();
  const { board } = useBoard({
    board: {
      page: utils.etc.getSearchParam().page || 1,
      type: 2,
      recordSize: 20,
    },
  });

  return (
    <Styles.Container className="articleWrap">
      <article className="recruitmentArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Grow with Us</div>
                <h2 className="articleTitle">인재 채용</h2>
              </>
            ) : (
              <>
                <div className="articleTitleSub">Grow with Us</div>
                <h2 className="articleTitle">Recruitment</h2>
              </>
            )}
          </div>

          <ul className="recruitmentList">
            {board.list?.map((item, index) => (
              <li className="recruitmentItem" key={"recruitmentItem" + index}>
                <Link to={`/recruitment/recruitment-list/detail/${item.id}`}>
                  <div className="number">
                    {board.pagination?.totalRecordCount -
                      index -
                      (board.pagination.page - 1) * 20}
                  </div>

                  <p className="title">{item["title" + suffix]}</p>

                  {item.hold_yn === "Y" && <div className="open">Open</div>}

                  <p className="date">
                    {utils.date.getDateType(item.created_at?.split(" ")[0])}
                  </p>
                </Link>
              </li>
            ))}
          </ul>

          <div className="pagination">
            <ui.Pagination
              page={board.pagination?.page}
              maxPage={board.pagination?.totalPageCount}
              list={board.pagination?.page_list}
            />
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default RecruitmentList;
