import Styles from "./styles";
import utils from "utils";
import { ui } from "components";
import { newsList } from "service/api/dummy";
import { IMAGEPATH } from "service/connector";
import { useBoard, useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";

// images
import Logo from "resources/image/icon/logo_gray.svg";

function AnnouncementsDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { detail } = useBoard({ detail: id });
  const { suffix } = useLanguage();

  return (
    <Styles.Container className="articleWrap">
      <article className="announcementsDetailArticle">
        <div className="inner">
          <p className="title">{detail["title" + suffix]}</p>

          <div className="thumbnailWrap">
            <div className="img">
              <img
                src={IMAGEPATH + detail.image}
                // alt={detail["title" + suffix]}
                style={{ backgroundColor: "#EFEFEF" }}
              />
            </div>

            <div className="textWrap">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <p className="official">Official Public Record</p>
              <p className="date">
                Date :{" "}
                {utils.date.getDateType(detail.created_at?.split(" ")[0])}
              </p>
            </div>
          </div>

          {detail.file_list?.length > 0 && (
            <ul className="fileList">
              {detail.file_list?.map((item, index) => (
                <li className="fileItem" key={"file" + index}>
                  <div className="attached">Attached</div>

                  <a href={item.api_uri_path} download rel="noreferrer">
                    {item.hashed_name}
                  </a>
                </li>
              ))}
            </ul>
          )}

          {/* 내부 style 지우고 사용 */}
          <div
            className="editorWrap ck-content"
            dangerouslySetInnerHTML={{
              __html: detail["description" + suffix],
            }}
          ></div>

          <div className="listButton">
            <ui.button.BasicButton title="List" onClick={() => navigate(-1)} />
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default AnnouncementsDetail;
