import styled from "styled-components";

const Container = styled.div`
  .articleTitleWrap {
    margin-bottom: 30px;
    font-weight: bold;
  }

  article:where(
      .technologyArticle01,
      .technologyArticle02,
      .technologyArticle03,
      .technologyArticle04,
      .technologyArticle05,
      .technologyArticle09
    )
    .imgWrap {
    height: 445px;
  }

  .technologyArticle06 {
    .imgWrap {
      height: 668px;
    }

    li {
      position: relative;
      padding-left: 20px;

      &::before {
        position: absolute;
        left: 0;
        content: "∙";
      }
    }
  }

  .technologyArticle07 {
    .imgWrap {
      height: 212px;
    }

    .subImgWrap {
      margin-top: 20px;
      height: 291px;
    }
  }

  .desc {
    margin-top: 20px;
    line-height: 1.4;
  }

  @media (max-width: 1400px) {
    .articleTitleWrap {
      margin-bottom: calc(100vw * (30 / 1400));
    }

    article:where(
        .technologyArticle01,
        .technologyArticle02,
        .technologyArticle03,
        .technologyArticle04,
        .technologyArticle05,
        .technologyArticle09
      )
      .imgWrap {
      height: calc(100vw * (445 / 1400));
    }

    .technologyArticle06 {
      .imgWrap {
        height: calc(100vw * (668 / 1400));
      }

      li {
        padding-left: calc(100vw * (20 / 1400));
      }
    }

    .technologyArticle07 {
      .imgWrap {
        height: calc(100vw * (212 / 1400));
      }

      .subImgWrap {
        margin-top: calc(100vw * (20 / 1400));
        height: calc(100vw * (291 / 1400));
      }
    }

    .desc {
      margin-top: calc(100vw * (20 / 1400));
    }
  }

  @media (max-width: 767px) {
    .articleTitleWrap {
      margin-bottom: calc(100vw * (20 / 375));
    }

    .technologyArticle01 {
      .imgWrap {
        height: calc(100vw * (442 / 375));
      }
    }

    .technologyArticle02 {
      .imgWrap {
        height: calc(100vw * (525 / 375));
      }
    }

    .technologyArticle03 {
      .imgWrap {
        height: calc(100vw * (1724 / 375));
      }
    }

    .technologyArticle04 {
      .imgWrap {
        height: calc(100vw * (1146 / 375));
      }
    }

    .technologyArticle05 {
      .imgWrap {
        height: calc(100vw * (474 / 375));
      }
    }

    .technologyArticle06 {
      .imgWrap {
        height: calc(100vw * (1216 / 375));
      }

      li {
        padding-left: calc(100vw * (20 / 375));
      }
    }

    .technologyArticle07 {
      .imgWrap {
        height: calc(100vw * (355 / 375));
      }

      .subImgWrap {
        margin-top: calc(100vw * (20 / 375));
        height: calc(100vw * (312 / 375));
      }
    }

    .technologyArticle08 {
      .imgWrap {
        height: calc(100vw * (446 / 375));
      }
    }

    .desc {
      margin-top: calc(100vw * (20 / 375));
      word-break: keep-all;
    }
  }
`;

export { Container };

export default {
  Container,
};
