import product from "./product";
import board from "./board";
import inquiry from "./inquiry";
import popup from "./popup";
import file from "./file";

export default {
  product,
  board,
  inquiry,
  popup,
  file,
};

export { product, board, inquiry, popup, file };
