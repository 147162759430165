import Styles from "./styles";
import { common, ui, layout } from "components";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCookie, IMAGEPATH } from "service/connector";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useBoard, useLanguage, useModals, usePopup } from "hooks";

//swiper
import "swiper/css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// images
import Logo from "resources/image/icon/logo_white.svg";
import IconScrollBottom from "resources/image/icon/icon_scroll_bottom.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_long.svg";
import ImgIntroduction01 from "resources/image/main/img_introduction01.jpg";
import ImgIntroduction02 from "resources/image/main/img_introduction02.jpg";
import ImgIntroduction03 from "resources/image/main/img_introduction03.jpg";
import ImgMenu01 from "resources/image/main/img_menu01.jpg";
import ImgMenu02 from "resources/image/main/img_menu02.jpg";
import ImgMenu03 from "resources/image/main/img_menu03.jpg";
import ImgMenu04 from "resources/image/main/img_menu04.jpg";
import ImgMenu01Mb from "resources/image/main/img_menu01_mb.jpg";

function MainPage() {
  const modalOption = useModals();
  const navigate = useNavigate();
  const [menuIndex, setMenuIndex] = useState(0);

  const { quarterLang, suffix } = useLanguage();
  const { board: banner } = useBoard({ board: { type: 9 } });
  const { board: notice } = useBoard({
    board: {
      recordSize: 2,
      type: 1,
    },
  });

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const introductionList = [
    {
      img: ImgIntroduction01,
      title: quarterLang ? (
        "국제 특허 및 기술 특허"
      ) : (
        <>
          International Patents
          <br /> and Technology Licenses
        </>
      ),
      description: quarterLang ? (
        <>
          국제 특허 및 일본 우수 석학의 기술 특허 계약을
          <br /> 보유하고 있습니다.
        </>
      ) : (
        <>
          Holding international patents
          <br /> and technology licenses from
          <br /> distinguished Japanese scholars.
        </>
      ),
    },
    {
      img: ImgIntroduction02,
      title: quarterLang ? (
        "항체 라이브러리 자체 제작"
      ) : (
        <>
          In-House Antibody
          <br /> Library Development
        </>
      ),
      description: quarterLang ? (
        <>
          다양한 항원에 대응 가능한 항체 라이브러리를 <br />
          제작 및 보유하고 있습니다.
        </>
      ) : (
        <>
          Creating and maintaining antibody
          <br /> libraries capable of responding
          <br /> to diverse antigens.
        </>
      ),
    },
    {
      img: ImgIntroduction03,
      title: quarterLang ? (
        "최신 의약품의 세계화 선도"
      ) : (
        <>
          Pioneering the Globalization
          <br /> of advanced Pharmaceuticals
        </>
      ),
      description: quarterLang ? (
        <>
          혁신적인 신약 후보물질을 개발하고 있어, <br />
          최신 의약품의 세계화를 선도합니다.
        </>
      ) : (
        <>
          Leading the globalization of advanced
          <br /> pharmaceuticals through innovative
          <br /> drug candidate development.
        </>
      ),
    },
  ];
  const menuList = [
    {
      title: quarterLang ? "기업소개" : "About Us",
      link: "/introduction/business",
      img: ImgMenu01,
    },
    {
      title: quarterLang ? "연구개발" : "R&D",
      link: "/research/technology",
      img: ImgMenu02,
    },
    {
      title: quarterLang ? "고객지원" : "Service Center",
      link: "/customer-support/announcements",
      img: ImgMenu03,
    },
    {
      title: quarterLang ? "인재채용" : "Careers",
      link: "/recruitment/talent",
      img: ImgMenu04,
    },
  ];

  useEffect(() => {
    if (getCookie("popup") == "hide") return;
    modalOption.setMainModal((e) => {
      e.show = true;
      return { ...e };
    });
  }, []);

  return (
    <>
      <common.Header main />
      <Styles.Container>
        <article className="bannerArticle">
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {banner?.list?.map((item, index) => (
              <SwiperSlide key={"banner" + index}>
                <img
                  src={`${IMAGEPATH}${isMobile ? item.thumbnail : item.image}`}
                  alt="banner"
                  style={{ cursor: item.url ? "pointer" : "default" }}
                  onClick={() => {
                    item.url && window.open(item.url, "_blank");
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="scrollBottom">
            <img src={IconScrollBottom} alt="scroll bottom" />
          </div>
        </article>

        <article className="aboutArticle">
          <div className="inner">
            {quarterLang ? (
              <h2>
                독자적인 인간 항체 라이브러리와 <br />
                뛰어난 자체 기술력으로 <br />
                혁신 신약 개발을 선도합니다.
              </h2>
            ) : (
              <h2>
                We lead the development of innovative new drugs <br />
                with our own synthetic antibody library and outstanding in-house
                technologies.
              </h2>
            )}

            <div className="moreButton">
              <ui.button.BasicButton
                title="About ABioTech"
                onClick={() => {
                  navigate("/introduction/business");
                }}
              />
            </div>
          </div>
        </article>

        <article className="introductionArticle">
          <div className="inner">
            {quarterLang ? (
              <h2>
                에이바이오텍은 항체 라이브러리 제작에 독보적인{" "}
                <br className="pcBr" />
                자체 기술력을 보유하고 있습니다.
              </h2>
            ) : (
              <h2>
                ABioTech possesses unique and proprietary technology <br />
                for developing antibody libraries.
              </h2>
            )}

            <ul className="introductionList">
              {introductionList.map((item, index) => (
                <li key={"introduction" + index}>
                  <div className="img">
                    <img src={item.img} alt={item.title} />
                  </div>
                  <p className="title">{item.title}</p>
                  <p className="description">{item.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </article>

        <article
          className="menuArticle"
          style={{
            backgroundImage: `url(${
              isMobile ? ImgMenu01Mb : menuList[menuIndex].img
            })`,
          }}
        >
          <div className="titleWrap">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
            {quarterLang ? (
              <h2>
                신약 개발의 혁신주자, <span>주식회사 에이바이오텍</span>
              </h2>
            ) : (
              <h2>A Pioneer in New Drug Development</h2>
            )}
          </div>

          <ul className="menuList">
            {menuList.map((item, index) => (
              <li
                className="menuItem"
                key={"menu" + index}
                onMouseOver={() => {
                  setMenuIndex(index);
                }}
              >
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </article>

        <article className="contactArticle">
          <div className="inner">
            <div className="newsWrap">
              <div className="titleWrap">
                <h2>News</h2>

                <Link to="/customer-support/announcements" className="newsLink">
                  <img src={IconArrowRight} alt="arrow" />
                </Link>
              </div>

              <ul className="newsList">
                {notice.list?.map((item, index) => (
                  <li className="newsItem" key={"news" + index}>
                    <layout.NewsList data={item} suffix={suffix} />
                  </li>
                ))}
              </ul>
            </div>

            <div className="customerCenterWrap">
              <h2>Service Center</h2>

              <ul className="customerCenterList">
                <li className="customerCenterItem">
                  <p className="title">Contact us</p>
                  <Link to="/customer-support/contact">
                    {quarterLang ? (
                      "고객 문의 바로가기"
                    ) : (
                      <>
                        Click here for
                        <br /> Service Center
                      </>
                    )}
                  </Link>
                </li>

                <li className="customerCenterItem">
                  <p className="title">E-mail</p>
                  <a href="mailto:admin@a-biotech.co.kr">
                    admin@a-biotech.co.kr
                  </a>
                </li>

                <li className="customerCenterItem">
                  <p className="title">
                    A/S Contact{" "}
                    <span className="date">Weekdays 09:00 - 18:00</span>
                  </p>
                  <a href="tel:+82231520034">02-3152-0034</a>
                </li>
              </ul>
            </div>
          </div>
        </article>

        <article className="qualityArticle">
          <div className="inner">
            <div className="textWrap">
              <h2>Certified Quality</h2>
              <p className="description">
                {quarterLang ? (
                  <>
                    고객의 가치를 최우선으로 생각하며, 지속적인 제품과 서비스
                    혁신을 통해
                    <br /> 고객들에게 끊임없이 더 높은 가치를 창출해 드리고자
                    각고의 노력을 기울이고 있습니다. <br />
                    “기본경영, 표준경영, 변화경영”의 경영방침을 바탕으로
                    끊임없이 변화하는 비즈니스 환경에 <br className="pcBr" />
                    발맞추며 성장해내가고 있습니다.
                  </>
                ) : (
                  <>
                    We prioritize delivering exceptional value to our customers
                    and continually
                    <br />
                    enhance it through innovation in products and services.
                    <br /> Guided by the principles of Fundamental Management,
                    <br /> Standardized Management, and Adaptive Management,
                    <br /> we are committed to adapting and thriving in an
                    ever-changing business environment.
                  </>
                )}
              </p>
            </div>

            <div className="moreButton">
              <ui.button.BasicButton
                title="View More"
                onClick={() => {
                  navigate("/recruitment/talent");
                }}
              />
            </div>
          </div>
        </article>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default MainPage;
