import utils from "utils";
import Styles from "./styles";
import { layout, ui } from "components";
import { useBoard, useLanguage } from "hooks";

function Announcements() {
  const { quarterLang, suffix } = useLanguage();
  const { board } = useBoard({
    board: {
      page: utils.etc.getSearchParam().page || 1,
      type: 1,
      recordSize: 6,
    },
  });

  return (
    <Styles.Container className="articleWrap">
      <article className="announcementsArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Announcements</div>
                <h2 className="articleTitle">공지사항</h2>
              </>
            ) : (
              <h2 className="articleTitle">Announcements</h2>
            )}
          </div>

          <ul className="newsList">
            {board?.list?.map((item, index) => (
              <li className="newsItem" key={"newsItem" + index}>
                <layout.NewsList data={item} suffix={suffix} big />
              </li>
            ))}
          </ul>

          <div className="pagination">
            <ui.Pagination
              page={board?.pagination?.page}
              maxPage={board?.pagination?.totalPageCount}
              list={board?.pagination?.page_list}
            />
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Announcements;
