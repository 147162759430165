import Styles from "./styles";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";

// images
import LogoWhite from "resources/image/icon/logo_white.svg";
import IconTop from "resources/image/icon/icon_top.svg";

function Footer() {
  const { quarterLang } = useLanguage();

  const list = quarterLang
    ? [
        "주식회사 에이바이오텍",
        "대표이사 : Yoshimi Koichi",
        "주소 : 서울특별시 마포구 매봉산로 37 DMC 산학협력연구센터 1305호",
        "사업자등록번호 : 888-88-00994",
        "TEL : +82-02-3152-0034",
        "E-mail : admin@a-biotech.co.kr",
      ]
    : [
        "ABioTech Co.,Ltd.",
        "CEO : Yoshimi Koichi",
        "Address : #1305, DMC Industry-Academic Cooperation Research Center, 37 Maebongsan-ro, Mapo-gu, Seoul, Republic of Korea",
        "Business Registration Number : 888-88-00994",
        "TEL : +82-02-3152-0034",
        "E-mail : admin@a-biotech.co.kr",
      ];

  const handleTopBtn = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Styles.Container>
      <div className="footerTop">
        <div className="logo">
          <img src={LogoWhite} alt="logo" />
        </div>

        <ul className="footerTopList">
          {list.map((item, index) => (
            <li key={"list" + index}>{item}</li>
          ))}
          <li>
            <Link to={"/terms/service-use"}>
              {quarterLang ? "이용약관" : "Terms of Use"}
            </Link>
          </li>
          <li>
            <Link to={"/terms/privacy"}>
              {quarterLang ? "개인정보처방침" : "Privacy Policy"}
            </Link>
          </li>
        </ul>
      </div>

      <p className="copyright">
        Copyright. 2024 ABioTech Co., Ltd. <br className="mbBr" /> All rights
        reserved.
      </p>

      <button type="button" className="topBtn" onClick={handleTopBtn}>
        <img src={IconTop} alt="top" />
      </button>
    </Styles.Container>
  );
}

export default Footer;
