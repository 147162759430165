import Styles from "./styles";
import { useLanguage } from "hooks";

// images
import IconLocation from "resources/image/icon/icon_location.svg";
import IconSubway from "resources/image/icon/icon_subway.svg";
import IconBus from "resources/image/icon/icon_bus.svg";

function Directions() {
  const { quarterLang } = useLanguage();

  const directionsInfoList = [
    {
      icon: IconLocation,
      content: quarterLang ? (
        <>
          서울시 마포구 매봉산로 37 <br />
          DMC 산학협력연구센터 1305호
        </>
      ) : (
        <>
          #1305, DMC Industry-Academic
          <br /> Cooperation Research Center, 37
          <br /> Maebongsan-ro, Mapo-gu, Seoul, Republic of Korea
        </>
      ),
    },
    {
      icon: IconSubway,
      content: quarterLang ? (
        <>
          <p>
            <span className="subway">6호선</span>
            <span className="station">디지털미디어씨티</span>
            <span>2번 출구에서 버스 환승</span>
          </p>
          <p>
            <span className="subway">공항철도</span>
            <span className="station">디지털미디어씨티</span>
            <span>9번 출구에서 도보 이동</span>
          </p>
        </>
      ) : (
        <>
          <p>
            <span className="subway">Line 6</span>
            <span className="station">DMC</span>
            <span>Bus transfer at exit 2</span>
          </p>
          <p>
            <span className="subway">A'REX</span>
            <span className="station">DMC</span>
            <span>Walk from Exit 9</span>
          </p>
        </>
      ),
    },
    {
      icon: IconBus,
      content: quarterLang ? (
        <>
          KBS 미디어센터 정류장 하차 <br />
          월드컵파크 2단지, 에스플렉스센터 <br className="mbBr" /> 정류장 하차
        </>
      ) : (
        <>
          - Get off the KBS Media Center stop
          <br />- World Cup Park complex 2,
          <br /> get off at the Esplex Center stop
        </>
      ),
    },
  ];

  const googleMap = {
    url1: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59638.24888181491!2d126.81692761680073!3d37.55891040811106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c997704b772d5%3A0x176a804a7a85f596!2z7JeQ7J2067CU7J207Jik7YWNKEFCaW90ZWNoKQ!5e0!3m2!1sk!2skr!4v1732785028273!5m2!1s${
      quarterLang ? "ko" : "en"
    }!2skr`,
  };

  return (
    <Styles.Container className="articleWrap">
      <article className="directionsArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Contact Us</div>
                <h2 className="articleTitle">오시는 길</h2>
              </>
            ) : (
              <h2 className="articleTitle">Contact Us</h2>
            )}
          </div>

          <div className="directionsContentWrap">
            <div className="mapWrap">
              <iframe
                src={googleMap.url1}
                width="700"
                height="418"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="directionsInfoWrap">
              <p className="company">
                {quarterLang ? "주식회사 에이바이오텍" : "ABiotech"}
              </p>

              <ul className="directionsInfoList">
                {directionsInfoList.map((item, index) => (
                  <li
                    className="directionsInfoItem"
                    key={"directionsInfoItem" + index}
                  >
                    <div className="icon">
                      <img src={item.icon} alt={item.title} />
                    </div>

                    <div className="content">{item.content}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Directions;
