import Styles from "./styles";
import { Outlet, useLocation } from "react-router-dom";
import { common, layout } from "components";

// images
import BgBanner from "resources/image/terms/bg_banner.jpg";
import BgBannerMb from "resources/image/terms/bg_banner_mb.jpg";

function TermsPage() {
  const location = useLocation();

  const path = location.pathname.split("/")[2];
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.MainBanner
          img={BgBanner}
          mbImg={BgBannerMb}
          title={path === "privacy" ? "개인정보처리방침" : "서비스 이용약관"}
          description={
            path === "privacy" ? (
              <>
                주식회사 에이바이오텍을 방문해 주셔서 감사합니다. <br />본
                사이트에서 획득하는 모든 정보는 개인정보 처리방침에 따르고
                있습니다.
              </>
            ) : (
              <>
                주식회사 에이바이오텍을 방문해 주셔서 감사합니다. <br />본
                사이트는 이용약관에 의거하여 운영되고 있습니다.
              </>
            )
          }
        />
        <div className="articleWrap">
          <Outlet />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default TermsPage;
