import Styles from "./styles";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalInner">
        <div className="modalTop">
          <p className="title">{modalOption.title}</p>

          {modalOption.text && <p className="text">{modalOption.text}</p>}
        </div>

        <div className="modalBottom">
          <button
            type="button"
            onClick={() => {
              modalClose();
            }}
          >
            <img src={IconClose} alt="close" />
          </button>
        </div>
      </div>

      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>
    </Styles.Container>
  );
}

export { DefaultModal };

export default DefaultModal;
