import styled from "styled-components";

const Container = styled.main`
  .articleWrap {
    padding: 80px 0 150px;
  }

  article:not(:last-of-type) {
    margin-bottom: 70px;
  }

  @media (max-width: 1400px) {
    .articleWrap {
      padding: calc(100vw * (80 / 1400)) 0 calc(100vw * (150 / 1400));
    }

    article:not(:last-of-type) {
      margin-bottom: calc(100vw * (70 / 1400));
    }
  }

  @media (max-width: 767px) {
    .articleWrap {
      padding: calc(100vw * (60 / 375)) 0 calc(100vw * (130 / 375));
    }

    article:not(:last-of-type) {
      margin-bottom: calc(100vw * (60 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
