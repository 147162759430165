import Styles from "./styles";

function BasicTextarea({ value, data, setInputs, onChange }) {
  const { id, labelText, name, placeholder, errorText } = data;

  const onChangeEvent = (e) => {
    const { name, value } = e.target;

    if (onChange) onChange(e);
    else setInputs((e) => ({ ...e, [name]: value }));
  };

  return (
    <Styles.Container>
      {labelText && (
        <label htmlFor={id || name}>
          {labelText}
          <span className="required">*</span>
        </label>
      )}

      <textarea
        id={id || name}
        name={name}
        placeholder={placeholder}
        value={value || data.value}
        onChange={onChangeEvent}
      ></textarea>

      {errorText && <p className="errorText">{errorText}</p>}
    </Styles.Container>
  );
}

export default BasicTextarea;
