import Styles from "./styles";
import { layout } from "components";
import { useLanguage } from "hooks";

// images
import ImgCeo from "resources/image/introduction/img_ceo.png";
import ImgCto from "resources/image/introduction/img_cto.png";

function Greeting() {
  const { quarterLang } = useLanguage();

  return (
    <Styles.Container className="articleWrap">
      <article className="ceoArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            <div className="articleTitleSub">ABioTech CEO</div>

            <h2 className="articleTitle">Yoshimi Koichi</h2>
          </div>

          <div className="imgWrap">
            <div className="textWrap">
              <p className="welcomeText">WELCOME to ABioTech</p>

              <p className="greetingText">
                {quarterLang ? (
                  <>
                    저희 기술을 통해 <br />
                    <b>암 치료에 새로운 선택지를</b> <br />
                    <b>제공할 수 있길</b> 진심으로 바랍니다.
                  </>
                ) : (
                  <>
                    We sincerely hope
                    <br /> that our technology
                    <b>
                      {" "}
                      can provide
                      <br /> new options for cancer treatment.
                    </b>
                  </>
                )}
              </p>

              <p className="ceoInfo">
                <span className="position">CEO</span>
                <span className="name">Yoshimi Koichi</span>
              </p>
            </div>

            <div className="img">
              <img src={ImgCeo} alt="CEO" />
            </div>
          </div>

          <div className="description">
            {quarterLang ? (
              <p>
                <b>
                  다양한 암 종에 적용 가능하며, 재발과 전이를 막고
                  <br className="pcBr" />
                  예방 효과도 갖춘 치료제를 창출하는 것이 저희의 목표입니다.
                </b>
              </p>
            ) : (
              <p>
                <b>
                  Our goal is to develop treatments that can be applied to
                  various
                  <br />
                  types of cancer, prevent recurrence and metastasis, and offer
                  preventive benefits.
                </b>
              </p>
            )}

            {quarterLang ? (
              <p>
                저희 에이바이오텍은 암 줄기세포를 표적으로 한 신약 개발을 통해
                암 치료의 혁신을 목표로 하고 있습니다.
                <br className="pcBr" />
                저희 연구는 일본의 저명한 유전학자인 시미즈 노부요시 교수님이
                개발한 항체 라이브러리와 항체 개발 기술을 기반으로 하고 있으며,
                <br className="pcBr" />
                이 기술을 계승하여 신규 항체 의약품 후보 물질의 개발에 도전하고
                있습니다.
                <br />
                <br />
                암은 여전히 전 세계적으로 많은 사람들의 생명을 위협하는 중대한
                질병입니다.
                <br className="pcBr" />
                암 줄기세포는 재발과 전이의 원인으로 여겨지며, 기존 치료법으로는
                충분히 대응하지 못하고 있는 것이 사실입니다.
                <br className="pcBr" />
                저희는 이 난제를 극복하기 위해 암 줄기세포를 특이적으로 표적하는
                신약 개발에 매진하고 있습니다.
                <br className="pcBr" />
                저희의 목표는 다양한 암 종에 적용 가능하며, 재발과 전이를 막고
                예방 효과도 갖춘 치료제를 창출하는 것입니다.
                <br />
                <br />
                저희 연구팀은 다양한 배경을 가진 전문가들이 모여, 날마다 새로운
                지식을 탐구하고 있으며,
                <br />
                기초 연구를 중시하면서도 임상 응용을 염두에 두고 개발을 진행하고
                있습니다.
              </p>
            ) : (
              <p>
                At ABioTech, we are committed to advancing innovative cancer
                therapies by targeting cancer stem cells.
                <br />
                Our research is based on the antibody library and development
                technology pioneered by the renowned Japanese geneticist,
                <br />
                Professor Nobuyoshi Shimizu. Building on this technology, we are
                striving to develop new antibody drug candidates.
                <br />
                <br />
                Cancer remains a major disease that threatens countless lives
                globally.
                <br />
                Cancer stem cells are considered the main cause of recurrence
                and metastasis,
                <br />
                and existing treatments have not been able to fully address
                these challenges.
                <br />
                To address this challenge, we are committed to developing new
                medicines that specifically target cancer stem cells.
                <br />
                Our goal is to create treatments that can be used in multiple
                cancer
                <br />
                types and that have a preventive effect while preventing
                recurrence and metastasis.
                <br />
                <br />
                Our research team consists of experts with diverse backgrounds,
                exploring new knowledge every day.
                <br />
                We focus on basic research, but are also committed to developing
                and translating this into clinical applications.
              </p>
            )}
            <p className="colorText">
              {quarterLang ? (
                <b>
                  저희 기술이 환자들에게 전달되어 암 치료에 새로운 선택지를
                  제공할 수 있기를 진심으로 바랍니다.
                </b>
              ) : (
                <b>
                  We sincerely hope that our technology will reach patients
                  <br />
                  and offer new treatment options for cancer.
                </b>
              )}
            </p>

            {quarterLang ? (
              <p>
                앞으로도 저희는 더욱 연구 개발을 진전시켜, 사회에 기여할 수 있는
                성과를 이루어 낼 것입니다. <br className="pcBr" />
                저희의 활동을 통해 많은 환자와 그 가족에게 희망을 전달할 수
                있도록 팀 모두가 하나 되어 노력해 나가겠습니다.{" "}
                <br className="pcBr" />
                또한, 여러분의 의견과 지도를 소중히 받아들이며, 더욱 성장할 수
                있기를 기대하고 있습니다
              </p>
            ) : (
              <p>
                Moving forward, we will continue to advance our research and
                development to achieve outcomes that contribute to society.
                <br />
                The entire team will work together as one to bring hope to many
                patients and their families through our activities.
                <br />
                We also value your opinions and guidance and look forward to
                continued growth.
              </p>
            )}
          </div>
        </div>
      </article>

      <article className="ctoArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            <div className="articleTitleSub">ABioTech CTO</div>

            <h2 className="articleTitle">Seno Masaharu</h2>
          </div>

          <div className="imgWrap">
            <div className="textWrap">
              <div className="textWrapTop">
                <p className="welcomeText">WELCOME to ABioTech</p>

                <p className="greetingText">
                  {quarterLang ? (
                    <>
                      인류의 건강과 복지에
                      <br /> <b>새로운 가치를 제공할 수 있도록</b>
                      <br className="pcBr" /> 최선을 다하고자 합니다.
                    </>
                  ) : (
                    <>
                      We are committed to delivering
                      <br /> <b>new value</b> to humanity's
                      <br /> health and well-being.
                    </>
                  )}
                </p>
              </div>

              <p className="ceoInfo">
                <span className="position">CTO</span>
                <span className="name">Seno Masaharu</span>
              </p>
            </div>

            <div className="img">
              <img src={ImgCto} alt="CTO" />
            </div>
          </div>

          <div className="description">
            {quarterLang ? (
              <p>
                <b>그간의 연구와 교육 경험을 바탕으로 창의적이고 도전적인</b>
                <br className="pcBr" />
                <b>
                  연구 환경을 조성하고, 최고의 성과를 낼 수 있도록 전력을 다할
                  것 입니다.
                </b>
              </p>
            ) : (
              <p>
                With years of research and teaching experience, we strive to
                cultivate an innovative
                <br /> and dynamic research environment that drives exceptional
                outcomes.
              </p>
            )}

            {quarterLang ? (
              <p>
                일본의 글로벌 제약회사에서 약 10년 동안 첨단 생명공학 연구 및
                혁신적인 기술 개발에 종사하였으며, <br className="pcBr" />
                이후 약 30년간 오카야마 <br className="mbBr" />
                대학에서 교수 및 명예교수로서 쌓아온 경험을 에이바이오텍에서
                계속해서 활용하고자 합니다.
                <br />
                <br />
                에이바이오텍은 세계적인 바이오 기술 및 신약 개발 분야에서 매우
                높은 잠재력을 가진 기업입니다.
                <br className="pcBr" />
                저희 연구팀은 국제적인 수준을 충족하는 연구와 협력을 통해
                혁신적인 항체 의약품 개발을 목표로 하고 있으며,
                <br className="pcBr" />
                인류의 건강과 복지에 새로운 가치를 제공할 수 있도록 최선을
                다하고자 합니다.
              </p>
            ) : (
              <p>
                Having contributed to groundbreaking life science research and
                innovative technology development at a global pharmaceutical
                company in Japan for nearly 10 years, and with over 30 years of
                accumulated expertise as a professor at Okayama University, I am
                eager to continue applying this wealth of experience at
                ABioTech.
                <br />
                <br />
                ABioTech is a company with extraordinary potential in the fields
                of biotechnology and drug development.
                <br />
                Our team is dedicated to achieving groundbreaking pharmaceutical
                advancements through international-standard research
                collaborations, ultimately contributing new value to global
                health and welfare.
              </p>
            )}

            <p className="colorText">
              {quarterLang ? (
                <b>
                  저희 연구소는 앞으로 단순한 연구의 틀을 넘어, 바이오 기술의
                  혁신적 발전을 주도하는 역할을 수행할 것입니다.
                </b>
              ) : (
                <b>
                  Our research team aims to go beyond simple research,
                  <br />
                  taking the lead in driving innovative advancements in
                  bio-technology.
                </b>
              )}
            </p>

            {quarterLang ? (
              <p>
                이를 위해 학계와 산업계를 잇는 중요한 가교 역할을 하면서 최신
                연구 성과가 신속하게 상업화 될 수 있도록 노력하겠습니다.{" "}
                <br className="pcBr" />
                이 과정에서 저는 그동안 쌓아온 연구와 교육 경험을 바탕으로
                창의적이고 도전적인 연구 환경을 조성하고,
                <br className="pcBr" />
                에이바이오텍의 모든 연구원들이 최고의 성과를 낼 수 있도록 전력을
                다할 것입니다.
              </p>
            ) : (
              <p>
                To this end, we will strengthen academic and industrial
                partnerships
                <br />
                to ensure the seamless commercialization of our latest research
                results. Throughout this journey,
                <br /> I will leverage my accumulated research and teaching
                experiences to foster a creative and challenging research
                environment that empowers ABioTech’s research teams to deliver
                the highest-quality outcomes.
              </p>
            )}

            <p className="colorText">
              {quarterLang ? (
                <b>
                  저희 에이바이오텍은 바이오 산업의 새로운 지평을 열기 위해
                  끊임없는 연구와 기술 개발을 지속해 나갈 것입니다.
                </b>
              ) : (
                <b>
                  ABioTech will continue to devote itself to pioneering new
                  frontiers in <br /> the bio-industry through relentless
                  research and technological innovation.
                </b>
              )}
            </p>

            {quarterLang ? (
              <p>
                이 목표를 달성하기 위해 저와 연구팀은 최선의 노력을 다할 것이며,{" "}
                <br className="pcBr" />
                앞으로도 저희 회사의 발전을 위해 여러분의 따뜻한 지원과 협력을
                부탁드리겠습니다. <br className="pcBr" />
                감사합니다.
              </p>
            ) : (
              <p>
                To achieve this goal, our research team will dedicate itself to
                continuous efforts,
                <br />
                and We sincerely welcome your unwavering support and
                collaboration as we strive for growth and success together.
              </p>
            )}
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Greeting;
