import styled from "styled-components";

const Container = styled.div`
  .directionsContentWrap {
    display: flex;
    gap: 60px;

    .mapWrap {
      width: 700px;
      height: 418px;
      background-color: var(--gray03);
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .company {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: bold;
  }

  .directionsInfoItem {
    display: flex;
    align-items: center;
    gap: 20px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .icon {
      width: 40px;
      height: 40px;
    }

    .content {
      font-weight: 500;
      color: var(--gray02);
      line-height: 1.4;

      & p {
        display: flex;
        align-items: center;
        gap: 10px;

        &:first-child {
          .subway,
          .station {
            color: var(--brown);
          }

          .subway {
            border-color: var(--brown);
          }
        }

        &:last-child {
          .subway,
          .station {
            color: var(--key01);
          }

          .subway {
            border-color: var(--key01);
          }
        }
      }

      .subway {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 21px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 20px;
        border: 1px solid var(--gray02);
      }
    }
  }

  @media (max-width: 1400px) {
    .directionsContentWrap {
      gap: calc(100vw * (60 / 1400));

      .mapWrap {
        width: calc(100vw * (700 / 1400));
        height: calc(100vw * (418 / 1400));
        border-radius: calc(100vw * (10 / 1400));
      }
    }

    .company {
      margin-bottom: calc(100vw * (50 / 1400));
      font-size: calc(100vw * (36 / 1400));
    }

    .directionsInfoItem {
      gap: calc(100vw * (20 / 1400));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 1400));
      }

      .icon {
        width: calc(100vw * (40 / 1400));
        height: calc(100vw * (40 / 1400));
      }

      .content {
        & p {
          gap: calc(100vw * (10 / 1400));
        }

        .subway {
          width: calc(100vw * (65 / 1400));
          height: calc(100vw * (21 / 1400));
          font-size: calc(100vw * (14 / 1400));
          border-radius: calc(100vw * (20 / 1400));
        }
      }
    }
  }

  @media (max-width: 767px) {
    .directionsContentWrap {
      flex-direction: column;
      gap: calc(100vw * (20 / 375));

      .mapWrap {
        width: calc(100vw * (335 / 375));
        height: calc(100vw * (200 / 375));
        border-radius: calc(100vw * (10 / 375));
      }
    }

    .company {
      margin-bottom: calc(100vw * (10 / 375));
      font-size: calc(100vw * (24 / 375));
    }

    .directionsInfoItem {
      gap: calc(100vw * (20 / 375));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (15 / 375));
      }

      .icon {
        width: calc(100vw * (30 / 375));
        height: calc(100vw * (30 / 375));
      }

      .content {
        & p {
          flex-wrap: wrap;
          gap: calc(100vw * (5 / 375)) calc(100vw * (10 / 375));

          &:first-child {
            margin-bottom: calc(100vw * (10 / 375));
          }
        }

        .subway {
          width: calc(100vw * (65 / 375));
          height: calc(100vw * (21 / 375));
          font-size: calc(100vw * (14 / 375));
          border-radius: calc(100vw * (20 / 375));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
