import styled from "styled-components";

// images
import BgAbout from "resources/image/main/bg_about.png";
import BgAboutMb from "resources/image/main/bg_about_mb.png";
import BgCustomerCenter from "resources/image/main/bg_customer_center.png";
import BgQuality from "resources/image/main/bg_quality.jpg";
import BgQualityMb from "resources/image/main/bg_quality_mb.jpg";

const Container = styled.main`
  padding-top: 0;

  h2 {
    font-size: 38px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -0.05em;
  }

  .bannerArticle {
    position: relative;

    .swiper-slide {
      height: 920px;
    }

    .scrollBottom {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 83px;
      height: 83px;
      z-index: 1;
    }
  }

  .aboutArticle {
    padding: 80px 0 126px;
    background: url(${BgAbout}) no-repeat center center / cover;

    .moreButton {
      width: 250px;
      margin-top: 50px;
    }
  }

  .introductionArticle {
    padding: 80px 0;

    h2 {
      margin-bottom: 30px;
      text-align: center;
    }

    .introductionList {
      display: flex;
      gap: 5px;

      .img {
        height: 360px;
      }

      .title {
        margin: 15px 0 5px;
        font-size: 24px;
        font-weight: 600;
        color: var(--gray02);
      }

      .description {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
      }
    }
  }

  .menuArticle {
    position: relative;
    transition: 0.3s;
    background-position: center;
    background-size: cover;

    .titleWrap {
      position: absolute;
      top: 86px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--white);

      .logo {
        width: 290px;
        height: 54px;
        margin-bottom: 32px;
      }
    }

    .menuList {
      display: flex;

      .menuItem {
        width: calc(100% / 4);

        &:not(:last-child) {
          border-right: 1px solid var(--white);
        }

        a {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 690px;
          padding-bottom: 100px;
          font-size: 38px;
          font-weight: 600;
          color: var(--white);
        }
      }
    }
  }

  .contactArticle {
    padding: 80px 0;

    .inner {
      display: flex;
      gap: 30px;
    }

    .titleWrap {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 30px;
    }

    .newsLink {
      width: 100px;
      height: 20px;
    }

    .newsList {
      display: flex;
      gap: 30px;
    }

    .customerCenterWrap {
      h2 {
        margin-bottom: 30px;
      }

      .customerCenterList {
        width: 480px;
        height: 428px;
        padding: 42px;
        background: url(${BgCustomerCenter}) var(--black) no-repeat bottom right /
          377px 59px;

        .customerCenterItem {
          &:first-child a {
            text-decoration: underline;
            text-underline-offset: 5px;
          }

          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        .title {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 600;
          color: var(--gray02);

          span {
            margin-left: 15px;
          }
        }

        a {
          font-size: 30px;
          font-weight: 600;
          color: var(--white);
        }
      }
    }
  }

  .qualityArticle {
    padding-bottom: 150px;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 400px;
      padding: 0 100px;
      color: var(--white);
      background: url(${BgQuality}) no-repeat center / cover;
    }

    h2 {
      margin-bottom: 15px;
    }

    .description {
      line-height: 1.6;
      letter-spacing: -0.05em;
    }

    .moreButton {
      width: 250px;
    }
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: calc(100vw * (38 / 1400));
    }

    .bannerArticle {
      .swiper-slide {
        height: calc(100vw * (920 / 1400));
      }

      .scrollBottom {
        bottom: calc(100vw * (50 / 1400));
        width: calc(100vw * (83 / 1400));
        height: calc(100vw * (83 / 1400));
      }
    }

    .aboutArticle {
      padding: calc(100vw * (80 / 1400)) 0 calc(100vw * (126 / 1400));

      .moreButton {
        width: calc(100vw * (250 / 1400));
        margin-top: calc(100vw * (50 / 1400));
      }
    }

    .introductionArticle {
      padding: calc(100vw * (80 / 1400)) 0;

      h2 {
        margin-bottom: calc(100vw * (30 / 1400));
      }

      .introductionList {
        gap: calc(100vw * (5 / 1400));

        .img {
          height: calc(100vw * (360 / 1400));
        }

        .title {
          margin: calc(100vw * (15 / 1400)) 0 calc(100vw * (5 / 1400));
          font-size: calc(100vw * (24 / 1400));
        }

        .description {
          font-size: calc(100vw * (18 / 1400));
        }
      }
    }

    .menuArticle {
      .titleWrap {
        top: calc(100vw * (86 / 1400));

        .logo {
          width: calc(100vw * (290 / 1400));
          height: calc(100vw * (54 / 1400));
          margin-bottom: calc(100vw * (32 / 1400));
        }
      }

      .menuList {
        .menuItem {
          a {
            height: calc(100vw * (690 / 1400));
            padding-bottom: calc(100vw * (100 / 1400));
            font-size: calc(100vw * (38 / 1400));
          }
        }
      }
    }

    .contactArticle {
      padding: calc(100vw * (80 / 1400)) 0;

      .inner {
        gap: calc(100vw * (30 / 1400));
      }

      .titleWrap {
        gap: calc(100vw * (20 / 1400));
        margin-bottom: calc(100vw * (30 / 1400));
      }

      .newsLink {
        width: calc(100vw * (100 / 1400));
        height: calc(100vw * (20 / 1400));
      }

      .newsList {
        gap: calc(100vw * (30 / 1400));
      }

      .customerCenterWrap {
        h2 {
          margin-bottom: calc(100vw * (30 / 1400));
        }

        .customerCenterList {
          width: calc(100vw * (480 / 1400));
          height: calc(100vw * (428 / 1400));
          padding: calc(100vw * (42 / 1400));
          background: url(${BgCustomerCenter}) var(--black) no-repeat bottom
            right / calc(100vw * (377 / 1400)) calc(100vw * (59 / 1400));

          .customerCenterItem {
            &:first-child a {
              text-underline-offset: calc(100vw * (5 / 1400));
            }

            &:not(:last-child) {
              margin-bottom: calc(100vw * (30 / 1400));
            }
          }

          .title {
            margin-bottom: calc(100vw * (10 / 1400));
            font-size: calc(100vw * (18 / 1400));

            span {
              margin-left: calc(100vw * (15 / 1400));
            }
          }

          a {
            font-size: calc(100vw * (30 / 1400));
          }
        }
      }
    }

    .qualityArticle {
      padding-bottom: calc(100vw * (150 / 1400));

      .inner {
        height: calc(100vw * (400 / 1400));
        padding: 0 calc(100vw * (100 / 1400));
      }

      h2 {
        margin-bottom: calc(100vw * (15 / 1400));
      }

      .moreButton {
        width: calc(100vw * (250 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: calc(100vw * (50 / 375));

    h2 {
      font-size: calc(100vw * (24 / 375));
      word-break: keep-all;
    }

    .bannerArticle {
      .swiper-slide {
        height: calc(100vw * (697 / 375));
      }

      .scrollBottom {
        bottom: calc(100vw * (30 / 375));
        width: calc(100vw * (83 / 375));
        height: calc(100vw * (83 / 375));
      }
    }

    .aboutArticle {
      padding: calc(100vw * (50 / 375)) 0 calc(100vw * (78 / 375));
      background-image: url(${BgAboutMb});

      .moreButton {
        width: calc(100vw * (221 / 375));
        margin-top: calc(100vw * (30 / 375));
      }
    }

    .introductionArticle {
      padding: calc(100vw * (60 / 375)) 0;

      h2 {
        margin-bottom: calc(100vw * (20 / 375));
      }

      .introductionList {
        flex-direction: column;
        gap: calc(100vw * (10 / 375));

        .img {
          height: calc(100vw * (280 / 375));
        }

        .title {
          margin: calc(100vw * (15 / 375)) 0 calc(100vw * (5 / 375));
          font-size: calc(100vw * (20 / 375));
        }

        .description {
          font-size: calc(100vw * (16 / 375));
        }
      }
    }

    .menuArticle {
      height: calc(100vw * (630 / 375));

      .titleWrap {
        position: static;
        padding: calc(100vw * (44 / 375)) calc(100vw * (20 / 375));
        transform: none;

        .logo {
          width: calc(100vw * (168 / 375));
          height: calc(100vw * (32 / 375));
          margin-bottom: calc(100vw * (20 / 375));
        }

        h2 {
          font-size: calc(100vw * (32 / 375));
          font-weight: bold;
        }
      }

      .menuList {
        flex-direction: column;

        .menuItem {
          width: 100%;

          a {
            justify-content: flex-start;
            align-items: center;
            height: calc(100vw * (100 / 375));
            padding: 0 calc(100vw * (20 / 375));
            font-size: calc(100vw * (24 / 375));
          }

          &:not(:last-child) {
            border-bottom: 1px solid var(--white);
            border-right: none;
          }
        }
      }
    }

    .contactArticle {
      padding: calc(100vw * (60 / 375)) 0;

      .inner {
        flex-direction: column;
        gap: calc(100vw * (57 / 375));
      }

      .titleWrap {
        gap: calc(100vw * (10 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }

      .newsLink {
        width: calc(100vw * (73 / 375));
        height: calc(100vw * (15 / 375));
      }

      .newsList {
        flex-direction: column;
        gap: calc(100vw * (10 / 375));
      }

      .customerCenterWrap {
        h2 {
          margin-bottom: calc(100vw * (20 / 375));
        }

        .customerCenterList {
          width: 100%;
          height: calc(100vw * (300 / 375));
          padding: calc(100vw * (25 / 375));
          background: url(${BgCustomerCenter}) var(--black) no-repeat bottom
            right / calc(100vw * (257 / 375)) calc(100vw * (40 / 375));

          .customerCenterItem {
            &:first-child a {
              text-underline-offset: calc(100vw * (4 / 375));
            }

            &:not(:last-child) {
              margin-bottom: calc(100vw * (20 / 375));
            }
          }

          .title {
            margin-bottom: calc(100vw * (5 / 375));
            font-size: calc(100vw * (16 / 375));

            span {
              margin-left: calc(100vw * (10 / 375));
            }
          }

          a {
            font-size: calc(100vw * (22 / 375));
          }
        }
      }
    }

    .qualityArticle {
      padding-bottom: calc(100vw * (130 / 375));

      .inner {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: calc(100vw * (450 / 375));
        padding: 0 calc(100vw * (25 / 375));
        background-image: url(${BgQualityMb});
      }

      h2 {
        margin-bottom: calc(100vw * (10 / 375));
      }

      .description {
        word-break: keep-all;
      }

      .moreButton {
        width: calc(100vw * (221 / 375));
        margin-top: calc(100vw * (30 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
