import styled from "styled-components";

// images
import BgContact from "resources/image/customer-support/bg_contact.jpg";
import BgContactMb from "resources/image/customer-support/bg_contact_mb.jpg";

const Container = styled.div`
  .contactContainer {
    border-radius: 5px;
    background: url(${BgContact}) no-repeat right center / 600px 100%;
    overflow: hidden;

    .contactWrap {
      width: 700px;
      padding: 40px 100px;
      background-color: var(--gray03);
    }

    .inputList {
      margin-bottom: 34px;
    }

    .inputItem:not(:last-child) {
      margin-bottom: 29px;
    }

    .fileUpload {
      margin-bottom: 60px;

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
        font-size: 22px;
        font-weight: 600;
        background-color: var(--white);
        border: 1px solid var(--key01);
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .fileText {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 100%;
      margin: -45px 0 24px;

      button {
        display: block;
        width: 20px;
        height: 20px;
      }

      p {
        width: calc(100% - 30px);
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .checkWrap {
      a {
        text-decoration: underline;
        text-underline-offset: 3px;
      }

      .required {
        color: var(--key01);
      }
    }

    .sendButton {
      width: 250px;
      margin: 60px auto 0;
    }
  }

  @media (max-width: 1400px) {
    .contactContainer {
      border-radius: calc(100vw * (5 / 1400));
      background: url(${BgContact}) no-repeat right center /
        calc(100vw * (600 / 1400)) 100%;

      .contactWrap {
        width: calc(100vw * (700 / 1400));
        padding: calc(100vw * (40 / 1400)) calc(100vw * (100 / 1400));
      }

      .inputList {
        margin-bottom: calc(100vw * (34 / 1400));
      }

      .inputItem:not(:last-child) {
        margin-bottom: calc(100vw * (29 / 1400));
      }

      .fileUpload {
        margin-bottom: calc(100vw * (60 / 1400));

        label {
          height: calc(100vw * (45 / 1400));
          font-size: calc(100vw * (22 / 1400));
          border-radius: calc(100vw * (5 / 1400));
        }
      }

      .fileText {
        gap: calc(100vw * (10 / 1400));
        margin: calc(100vw * (-45 / 1400)) 0 calc(100vw * (24 / 1400));

        button {
          width: calc(100vw * (20 / 1400));
          height: calc(100vw * (20 / 1400));
        }

        p {
          width: calc(100% - calc(100vw * (30 / 1400)));
          font-size: calc(100vw * (16 / 1400));
        }
      }

      .checkWrap {
        a {
          text-underline-offset: calc(100vw * (3 / 1400));
        }
      }

      .sendButton {
        width: calc(100vw * (250 / 1400));
        margin: calc(100vw * (60 / 1400)) auto 0;
      }
    }
  }

  @media (max-width: 767px) {
    .contactContainer {
      padding-bottom: calc(100vw * (220 / 375));
      border-radius: calc(100vw * (5 / 375));
      background: url(${BgContactMb}) no-repeat center bottom / 100%
        calc(100vw * (220 / 375));

      .contactWrap {
        width: 100%;
        padding: calc(100vw * (20 / 375)) calc(100vw * (11 / 375));
      }

      .inputList {
        margin-bottom: calc(100vw * (30 / 375));
      }

      .inputItem:not(:last-child) {
        margin-bottom: calc(100vw * (27 / 375));
      }

      .fileUpload {
        margin-bottom: calc(100vw * (55 / 375));

        label {
          height: calc(100vw * (40 / 375));
          font-size: calc(100vw * (18 / 375));
          border-radius: calc(100vw * (5 / 375));
        }
      }

      .fileText {
        gap: calc(100vw * (10 / 375));
        margin: calc(100vw * (-40 / 375)) 0 calc(100vw * (20 / 375));

        button {
          width: calc(100vw * (20 / 375));
          height: calc(100vw * (20 / 375));
        }

        p {
          width: calc(100% - calc(100vw * (30 / 375)));
          font-size: calc(100vw * (14 / 375));
        }
      }

      .checkWrap {
        a {
          text-underline-offset: calc(100vw * (3 / 375));
        }
      }

      .sendButton {
        width: calc(100vw * (221 / 375));
        margin: calc(100vw * (30 / 375)) auto 0;
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
