import styled from "styled-components";

const Container = styled.div`
  .articleTitleWrap {
    margin-bottom: 45px;
  }

  .imgWrap {
    position: relative;
    height: 410px;
    margin-bottom: 50px;

    .welcomeText {
      position: relative;
      margin-bottom: 25px;
      padding-bottom: 15px;
      font-size: 18px;
      font-weight: 600;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 88px;
        height: 1px;
        background-color: var(--white);
        content: "";
      }
    }

    .greetingText {
      font-size: 38px;
      line-height: 1.4;

      b {
        font-weight: 600;
      }
    }

    .ceoInfo {
      font-size: 18px;

      .position {
        margin-right: 15px;
        font-weight: 600;
      }

      .name {
        font-weight: 500;
      }
    }
  }

  .description {
    width: 1088px;
    margin: 0 auto;
    line-height: 1.4;

    p:not(:last-child) {
      margin-bottom: 20px;
    }

    b {
      font-size: 24px;
      font-weight: 600;
    }

    .colorText {
      color: var(--key01);
    }
  }

  .ceoArticle {
    .imgWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 90px;
      color: var(--white);
      background: var(--gradient);
    }

    .greetingText {
      margin-bottom: 30px;
    }

    .img {
      position: absolute;
      bottom: 0;
      right: 190px;
      width: 379px;
      height: 539px;
    }
  }

  .ctoArticle {
    .textWrapTop,
    .ceoInfo {
      display: flex;
      padding-left: 491px;
    }

    .textWrapTop {
      height: 310px;
      justify-content: center;
      flex-direction: column;
      color: var(--white);
      background: var(--gradient);
      border-top-right-radius: 80px;
    }

    .ceoInfo {
      align-items: center;
      height: 100px;
      background-color: var(--gray03);
    }

    .img {
      position: absolute;
      bottom: 0;
      left: 67px;
      width: 291px;
      height: 382px;
    }
  }

  @media (max-width: 1400px) {
    .articleTitleWrap {
      margin-bottom: calc(100vw * (45 / 1400));
    }

    .imgWrap {
      height: calc(100vw * (410 / 1400));
      margin-bottom: calc(100vw * (50 / 1400));

      .welcomeText {
        margin-bottom: calc(100vw * (25 / 1400));
        padding-bottom: calc(100vw * (15 / 1400));
        font-size: calc(100vw * (18 / 1400));

        &::after {
          width: calc(100vw * (88 / 1400));
        }
      }

      .greetingText {
        font-size: calc(100vw * (38 / 1400));
      }

      .ceoInfo {
        font-size: calc(100vw * (18 / 1400));

        .position {
          margin-right: calc(100vw * (15 / 1400));
        }
      }
    }

    .description {
      width: calc(100vw * (1088 / 1400));

      p:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 1400));
      }

      b {
        font-size: calc(100vw * (24 / 1400));
      }
    }

    .ceoArticle {
      .imgWrap {
        padding: 0 calc(100vw * (90 / 1400));
      }

      .greetingText {
        margin-bottom: calc(100vw * (30 / 1400));
      }

      .img {
        right: calc(100vw * (190 / 1400));
        width: calc(100vw * (379 / 1400));
        height: calc(100vw * (539 / 1400));
      }
    }

    .ctoArticle {
      .textWrapTop,
      .ceoInfo {
        padding-left: calc(100vw * (491 / 1400));
      }

      .textWrapTop {
        height: calc(100vw * (310 / 1400));
        border-top-right-radius: calc(100vw * (80 / 1400));
      }

      .ceoInfo {
        height: calc(100vw * (100 / 1400));
      }

      .img {
        left: calc(100vw * (67 / 1400));
        width: calc(100vw * (291 / 1400));
        height: calc(100vw * (382 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    .articleTitleWrap {
      margin-bottom: calc(100vw * (20 / 375));
    }

    .imgWrap {
      height: auto;
      margin-bottom: calc(100vw * (30 / 375));

      .welcomeText {
        margin-bottom: calc(100vw * (25 / 365));
        padding-bottom: calc(100vw * (15 / 365));
        font-size: calc(100vw * (16 / 375));

        &::after {
          width: calc(100vw * (88 / 375));
        }
      }

      .greetingText {
        font-size: calc(100vw * (24 / 375));
        word-break: keep-all;
      }

      .ceoInfo {
        font-size: calc(100vw * (16 / 375));

        .position {
          margin-right: calc(100vw * (15 / 375));
        }
      }
    }

    .description {
      width: 100%;
      word-break: keep-all;

      p:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 375));
      }

      b {
        font-size: calc(100vw * (20 / 375));
      }
    }

    .ceoArticle {
      .imgWrap {
        gap: calc(100vw * (30 / 375));
        padding: calc(100vw * (25 / 375)) calc(100vw * (25 / 375)) 0;
      }

      .greetingText {
        margin-bottom: calc(100vw * (30 / 375));
      }

      .img {
        position: static;
        width: 100%;
        height: calc(100vw * (405 / 375));
      }
    }

    .ctoArticle {
      .textWrapTop {
        justify-content: flex-end;
        padding: calc(100vw * (34 / 375)) calc(100vw * (25 / 375));
      }

      .ceoInfo {
        padding: 0 calc(100vw * (25 / 375));
      }

      .textWrapTop {
        height: calc(100vw * (663 / 375));
        border-top-right-radius: calc(100vw * (55 / 375));
      }

      .ceoInfo {
        height: calc(100vw * (67 / 375));
      }

      .img {
        top: calc(100vw * (40 / 375));
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        width: calc(100vw * (277 / 375));
        height: calc(100vw * (364 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
