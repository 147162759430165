import { DefaultModal, ToastModal, MainModal } from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

// img
export default function ModalGeneratePage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (modalOption.defaultModal.show || modalOption.toastModal.show) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //기본 모달
        modalOption.mainModal.show ? (
          <MainModal
            modalOption={modalOption.mainModal}
            modalClose={() => {
              modalOption.setMainModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //토스트 메세지 모달
        modalOption.toastModal.show ? (
          <ToastModal
            modalOption={modalOption.toastModal}
            modalClose={() => {
              modalOption.setToastModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
