import MainBanner from "./MainBanner";
import Navigation from "./Navigation";
import NewsList from "./NewsList";

export { MainBanner, Navigation, NewsList };

export default {
  MainBanner,
  Navigation,
  NewsList,
};
