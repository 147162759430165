import styled from "styled-components";

const Container = styled.footer`
  .footerTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 268px;
    background: var(--gradient);

    .logo {
      width: 218px;
      height: 41px;
      margin-bottom: 50px;
    }

    .footerTopList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      max-width: 1060px;
      font-size: 16px;
      font-weight: 500;
      color: var(--white);

      a {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    font-size: 16px;
    font-weight: 500;
    background-color: var(--gray01);
  }

  .topBtn {
    position: fixed;
    bottom: 50px;
    right: 80px;
    display: block;
    width: 67px;
    height: 67px;
    z-index: 5;
  }

  @media (max-width: 1400px) {
    .footerTop {
      height: calc(100vw * (268 / 1400));

      .logo {
        width: calc(100vw * (218 / 1400));
        height: calc(100vw * (41 / 1400));
        margin-bottom: calc(100vw * (50 / 1400));
      }

      .footerTopList {
        gap: calc(100vw * (20 / 1400));
        max-width: calc(100vw * (1060 / 1400));
        font-size: calc(100vw * (16 / 1400));

        a {
          text-underline-offset: calc(100vw * (3 / 1400));
        }
      }
    }

    .copyright {
      height: calc(100vw * (55 / 1400));
      font-size: calc(100vw * (16 / 1400));
    }

    .topBtn {
      bottom: calc(100vw * (50 / 1400));
      right: calc(100vw * (80 / 1400));
      width: calc(100vw * (67 / 1400));
      height: calc(100vw * (67 / 1400));
    }
  }

  @media (max-width: 767px) {
    .footerTop {
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
      padding: calc(100vw * (26 / 375)) calc(100vw * (20 / 375))
        calc(100vw * (25 / 375));

      .logo {
        width: calc(100vw * (150 / 375));
        height: calc(100vw * (28 / 375));
        margin-bottom: calc(100vw * (30 / 375));
      }

      .footerTopList {
        flex-direction: column;
        justify-content: flex-start;
        gap: calc(100vw * (5 / 375));
        max-width: 100%;
        font-size: calc(100vw * (16 / 375));
        line-height: 1.4;

        a {
          display: none;
          text-underline-offset: calc(100vw * (3 / 375));
        }
      }
    }

    .copyright {
      height: calc(100vw * (55 / 375));
      font-size: calc(100vw * (16 / 375));
      text-align: center;
    }

    .topBtn {
      bottom: calc(100vw * (70 / 375));
      right: calc(100vw * (10 / 375));
      width: calc(100vw * (50 / 375));
      height: calc(100vw * (50 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
