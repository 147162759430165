import { useLanguage } from "hooks";
import Styles from "./styles";
import { useMediaQuery } from "react-responsive";

// images
import ImgOrganization from "resources/image/introduction/img_organization.jpg";
import ImgOrganizationMb from "resources/image/introduction/img_organization_mb.jpg";

function Organization() {
  const { quarterLang } = useLanguage();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Styles.Container className="articleWrap">
      <article className="organizationArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Organization</div>
                <h2 className="articleTitle">조직도</h2>
              </>
            ) : (
              <h2 className="articleTitle">Organization</h2>
            )}
          </div>

          <div className="organizationImgWrap">
            <img
              src={isMobile ? ImgOrganizationMb : ImgOrganization}
              alt={quarterLang ? "조직도" : "Organization"}
            />
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Organization;
