import styled from "styled-components";

// image
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray03);
  transition: background-color 0.3s ease;
  z-index: 8;

  &.main {
    background-color: transparent;
    border: none;

    .menuListWrap {
      color: var(--white);
    }
  }

  .inner {
    display: flex;
    align-items: center;
    height: 80px;
  }

  .logo {
    width: 150px;
    height: 28px;
  }

  .menuListWrap {
    display: flex;
    gap: 45px;
    margin-left: auto;
    font-size: 18px;
    font-weight: 600;
  }

  .menuList {
    transition: color 0.3s ease;

    &:hover {
      color: var(--key01);
    }

    a.on {
      color: var(--key01);
    }
  }

  .languageListWrap {
    display: flex;
    margin-left: 45px;

    button {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: var(--gray02);

      &:first-child {
        position: relative;
        margin-right: 20px;
        padding-right: 20px;

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 16px;
          background-color: var(--gray02);
          content: "";
        }
      }

      &.on {
        color: var(--key01);
      }
    }
  }

  @media (max-width: 1400px) {
    .inner {
      height: calc(100vw * (80 / 1400));
    }

    .logo {
      width: calc(100vw * (150 / 1400));
      height: calc(100vw * (28 / 1400));
    }

    .menuListWrap {
      gap: calc(100vw * (45 / 1400));
      font-size: calc(100vw * (18 / 1400));
    }

    .languageListWrap {
      margin-left: calc(100vw * (45 / 1400));

      button {
        font-size: calc(100vw * (16 / 1400));

        &:first-child {
          margin-right: calc(100vw * (20 / 1400));
          padding-right: calc(100vw * (20 / 1400));

          &::after {
            height: calc(100vw * (16 / 1400));
          }
        }
      }
    }
  }
`;

const MobileContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray03);
  z-index: 8;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(100vw * (50 / 375));
  }

  .logo {
    width: calc(100vw * (95 / 375));
    height: calc(100vw * (18 / 375));
  }

  .menuButton {
    display: block;
    width: calc(100vw * (32 / 375));
    height: calc(100vw * (32 / 375));
  }
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  z-index: 9;

  .navTop {
    border-bottom: 1px solid var(--gray03);

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: calc(100vw * (50 / 375));
    }

    .languageListWrap {
      display: flex;

      button {
        display: block;
        font-size: calc(100vw * (14 / 375));
        font-weight: 600;
        color: var(--gray02);

        &:first-child {
          position: relative;
          margin-right: calc(100vw * (15 / 375));
          padding-right: calc(100vw * (15 / 375));

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: calc(100vw * (16 / 375));
            background-color: var(--gray02);
            content: "";
          }
        }

        &.on {
          color: var(--key01);
        }
      }
    }

    .closeButton {
      display: block;
      width: calc(100vw * (24 / 375));
      height: calc(100vw * (24 / 375));
    }
  }

  .menuWrap {
    padding-top: calc(100vw * (30 / 375));

    .menuList a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: calc(100vw * (50 / 375));
      font-size: calc(100vw * (16 / 375));
      font-weight: 500;
      border-bottom: 1px solid var(--gray01);
      background: url(${IconArrowRight}) no-repeat right center /
        calc(100vw * (24 / 375));
    }
  }
`;

export { Container, MobileContainer, Nav };

export default {
  Container,
  MobileContainer,
  Nav,
};
