import styled from "styled-components";

const Container = styled.div`
  .recruitmentItem {
    &:nth-child(odd) {
      background-color: var(--gray03);
    }

    a {
      display: flex;
      align-items: center;
      height: 55px;
      font-size: 22px;
      font-weight: 600;

      .number {
        width: 150px;
        color: var(--key01);
        text-align: center;
      }

      .title {
        width: 736px;
        padding: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .open {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 165px;
        height: 44px;
        margin-left: 21px;
        color: var(--white);
        background-color: var(--key01);
        border-radius: 5px;
      }

      .date {
        width: 207px;
        margin-left: auto;
        font-size: 18px;
        color: var(--gray02);
        text-align: center;
      }
    }
  }

  .pagination {
    margin: 80px auto 0;
  }

  @media (max-width: 1400px) {
    .recruitmentItem {
      a {
        height: calc(100vw * (55 / 1400));
        font-size: calc(100vw * (22 / 1400));

        .number {
          width: calc(100vw * (150 / 1400));
        }

        .title {
          width: calc(100vw * (736 / 1400));
          padding: calc(100vw * (35 / 1400));
        }

        .open {
          width: calc(100vw * (165 / 1400));
          height: calc(100vw * (44 / 1400));
          margin-left: calc(100vw * (21 / 1400));
          border-radius: calc(100vw * (5 / 1400));
        }

        .date {
          width: calc(100vw * (207 / 1400));
          font-size: calc(100vw * (18 / 1400));
        }
      }
    }

    .pagination {
      margin: calc(100vw * (80 / 1400)) auto 0;
    }
  }

  @media (max-width: 767px) {
    .recruitmentItem {
      a {
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        height: calc(100vw * (105 / 375));
        padding: 0 calc(100vw * (10 / 375));
        font-size: calc(100vw * (18 / 375));

        .number {
          display: none;
        }

        .title {
          width: 100%;
          margin-bottom: calc(100vw * (10 / 375));
          padding: 0;
        }

        .open {
          width: calc(100vw * (102 / 375));
          height: calc(100vw * (31 / 375));
          margin-left: 0;
          border-radius: calc(100vw * (5 / 375));
        }

        .date {
          width: auto;
          font-size: calc(100vw * (16 / 375));
        }
      }
    }

    .pagination {
      margin: calc(100vw * (60 / 375)) auto 0;
    }
  }
`;

export { Container };

export default {
  Container,
};
