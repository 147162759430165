import styled from "styled-components";

const Container = styled.div`
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 380px;
    height: 428px;

    &.big {
      width: 426px;
      height: 478px;

      .hover,
      .img {
        height: 358px;
      }
    }

    &:hover {
      .hover {
        opacity: 1;
      }
    }

    .hover {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 320px;
      font-size: 36px;
      font-weight: 600;
      color: var(--white);
      background: rgba(0, 142, 207, 0.4);
      border-radius: 5px;
      content: "";
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      .arrowIcon {
        margin-top: 20px;
      }
    }

    .img {
      height: 320px;
      margin-bottom: 15px;
      border-radius: 5px;
      overflow: hidden;
    }

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.4;
      white-space: pre-line;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .date {
      margin-top: auto;
      font-size: 18px;
      font-weight: 600;
      color: var(--gray02);
    }
  }

  @media (max-width: 1400px) {
    a {
      width: calc(100vw * (380 / 1400));
      height: calc(100vw * (428 / 1400));

      &.big {
        width: calc(100vw * (426 / 1400));
        height: calc(100vw * (478 / 1400));

        .hover,
        .img {
          height: calc(100vw * (358 / 1400));
        }
      }

      .hover {
        height: calc(100vw * (320 / 1400));
        font-size: calc(100vw * (36 / 1400));
        border-radius: calc(100vw * (5 / 1400));

        .arrowIcon {
          margin-top: calc(100vw * (20 / 1400));
        }
      }

      .img {
        height: calc(100vw * (320 / 1400));
        margin-bottom: calc(100vw * (15 / 1400));
        border-radius: calc(100vw * (5 / 1400));
      }

      .title {
        margin-bottom: calc(100vw * (5 / 1400));
        font-size: calc(100vw * (22 / 1400));
      }

      .date {
        font-size: calc(100vw * (18 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    a {
      width: 100%;
      height: auto;

      &.big {
        width: 100%;
        height: auto;

        .hover,
        .img {
          height: calc(100vw * (282 / 375));
        }
      }

      .hover {
        display: none;
      }

      .img {
        height: calc(100vw * (282 / 375));
        margin-bottom: calc(100vw * (15 / 375));
        border-radius: calc(100vw * (5 / 375));
      }

      .title {
        margin-bottom: calc(100vw * (5 / 375));
        font-size: calc(100vw * (18 / 375));
      }

      .date {
        font-size: calc(100vw * (16 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
