import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460px;
  background-color: #fff;
  border: 1px solid var(--black);
  border-radius: 5px;
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  z-index: 5;

  .modalImg {
    height: 510px;
  }

  .modalBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 30px;
    border-top: 1px solid var(--black);

    .closeButton {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1400px) {
    width: calc(100vw * (460 / 1400));
    border-radius: calc(100vw * (5 / 1400));
    box-shadow: calc(100vw * (10 / 1400)) calc(100vw * (10 / 1400))
      calc(100vw * (10 / 1400)) 0 rgba(0, 0, 0, 0.05);

    .modalImg {
      height: calc(100vw * (510 / 1400));
    }

    .modalBottom {
      height: calc(100vw * (50 / 1400));
      padding: 0 calc(100vw * (30 / 1400));

      .closeButton {
        width: calc(100vw * (20 / 1400));
        height: calc(100vw * (20 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    width: calc(100vw * (326 / 375));
    border-radius: calc(100vw * (5 / 375));
    box-shadow: calc(100vw * (10 / 375)) calc(100vw * (10 / 375))
      calc(100vw * (10 / 375)) 0 rgba(0, 0, 0, 0.05);

    .modalImg {
      height: calc(100vw * (361 / 375));
    }

    .modalBottom {
      height: calc(100vw * (37 / 375));
      padding: 0 calc(100vw * (20 / 375));

      .closeButton {
        width: calc(100vw * (20 / 375));
        height: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
