import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { useLanguage } from "hooks";
import { common, layout } from "components";

// images
import BgBanner from "resources/image/research/bg_banner.jpg";
import BgBannerMb from "resources/image/research/bg_banner_mb.jpg";

function ResearchPage() {
  const { quarterLang } = useLanguage();

  const tabList = [
    { title: "Technology", link: "/research/technology" },
    { title: "Pipeline", link: "/research/pipeline" },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.MainBanner
          img={BgBanner}
          mbImg={BgBannerMb}
          title={quarterLang ? "연구개발" : "R&D"}
          description={
            quarterLang ? (
              <>
                주식회사 에이바이오텍을 방문해 주셔서 감사합니다. <br />
                독자적인 인간 항체 라이브러리 기술을 이용하여 혁신 의약품을
                개발하고 있습니다.
              </>
            ) : (
              <>
                Thank you for visiting ABioTech Co., Ltd.
                <br />
                We are developing innovative pharmaceuticals using our
                proprietary synthetic antibody library technology.
              </>
            )
          }
        />
        <layout.Navigation tabList={tabList} />
        <Outlet />
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ResearchPage;
