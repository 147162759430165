import Styles from "./styles";
import { ui } from "components";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { reqValid } from "utils";
import { useInquiry, useLanguage, useModals } from "hooks";

// images
import DeleteIcon from "resources/image/icon/icon_close.svg";
import { api } from "service";

function Contact() {
  const { quarterLang } = useLanguage();
  const modalOption = useModals();
  const inquiryInfo = useInquiry();

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    organization: "",
    phone: "",
    message: "",
  });
  const [file, setFile] = useState(null);
  const [checked, setChecked] = useState(false);
  const [wording, setWording] = useState({});

  const data = [
    {
      id: "name",
      labelText: quarterLang ? "성함" : "Name",
      name: "name",
      placeholder: "Insert your name here.",
      errorText: wording.name,
    },
    {
      id: "email",
      labelText: quarterLang ? "이메일" : "Email",
      name: "email",
      placeholder: "Insert your email address here.",
      errorText: wording.email,
    },
    {
      id: "organization",
      labelText: quarterLang
        ? "소속(회사 또는 학교명)"
        : "Affiliation (Company/School Name)",
      name: "organization",
      placeholder: "Insert your organization here.",
      errorText: wording.organization,
    },
    {
      id: "phone",
      labelText: quarterLang ? "연락처" : "Phone",
      name: "phone",
      placeholder: "ex)010-1234-5678",
      errorText: wording.phone,
    },
    {
      id: "message",
      labelText: quarterLang ? "문의 내용" : "Message",
      name: "message",
      placeholder: "Please feel free to contact us anytime.",
      errorText: wording.message,
    },
  ];

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    setInputs((e) => ({ ...e, [name]: value }));
  };

  const validate = () => {
    setWording({});
    let isError = false;

    if (reqValid.required(inputs.name)) {
      setWording((e) => ({ ...e, name: "성함을 입력해 주세요." }));
      isError = true;
    }
    if (reqValid.required(inputs.email)) {
      setWording((e) => ({ ...e, email: "이메일 주소를 입력해 주세요." }));
      isError = true;
    }
    if (reqValid.required(inputs.organization)) {
      setWording((e) => ({ ...e, organization: "소속을 입력해 주세요." }));
      isError = true;
    }
    if (reqValid.required(inputs.phone)) {
      setWording((e) => ({ ...e, phone: "연락처를 입력해 주세요." }));
      isError = true;
    }
    if (reqValid.required(inputs.message)) {
      setWording((e) => ({ ...e, message: "문의 내용을 입력해 주세요." }));
      isError = true;
    }

    if (isError) return false;
    return true;
  };

  const onContinue = () => {
    if (!validate()) return;
    if (!checked) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "Alert";
        e.text = "개인정보 처리방침에 동의해 주세요.";

        return { ...e };
      });
      return;
    }
    inquiryInfo.postInquiry(
      {
        company: inputs.organization,
        contact: inputs.name,
        content: inputs.message,
        email: inputs.email,
        phone: inputs.phone?.replace(/-/g, ""),
      },
      {
        success: async (e) => {
          if (e.success == false) {
            alert(e.message);
            return;
          }

          console.log(e);
          if (file != null) {
            const form = new FormData();
            form.append("file", file);
            form.append("id", e.data);
            await api.file.uploadFile(form).then((res) => {
              console.log(res);
            });
          }
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.title = "Complete";
            e.text = quarterLang ? (
              <>
                문의가 등록되었습니다. <br />
                영업일 기준 2~3일 내로 담당자가 연락드리도록 하겠습니다.
              </>
            ) : (
              <>
                Your inquiry has been successfully submitted.
                <br />
                Our team will get back to you within 2-3 business days.
              </>
            );
            return { ...e };
          });
        },
      }
    );
  };

  return (
    <Styles.Container className="articleWrap">
      <article className="contactArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Contact Us</div>
                <h2 className="articleTitle">고객문의</h2>
              </>
            ) : (
              <h2 className="articleTitle">Contact Us</h2>
            )}
          </div>

          <div className="contactContainer">
            <div className="contactWrap">
              <form action="">
                <ul className="inputList">
                  <li className="inputItem">
                    <ui.input.BasicInput
                      data={data[0]}
                      value={inputs.name}
                      onChange={onChangeEvent}
                    />
                  </li>

                  <li className="inputItem">
                    <ui.input.BasicInput
                      data={data[1]}
                      value={inputs.email}
                      onChange={onChangeEvent}
                    />
                  </li>

                  <li className="inputItem">
                    <ui.input.BasicInput
                      data={data[2]}
                      value={inputs.organization}
                      onChange={onChangeEvent}
                    />
                  </li>

                  <li className="inputItem">
                    <ui.input.BasicInput
                      data={data[3]}
                      value={inputs.phone}
                      onChange={reqValid.phoneFormatting(setInputs)}
                    />
                  </li>

                  <li className="inputItem">
                    <ui.input.BasicTextarea
                      data={data[4]}
                      value={inputs.message}
                      onChange={onChangeEvent}
                    />
                  </li>
                </ul>

                <div className="fileUpload">
                  <input
                    type="file"
                    id="fileUpload"
                    hidden
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />

                  <label htmlFor="fileUpload">Attach File</label>
                </div>

                {file !== null && (
                  <div className="fileText">
                    <button type="button">
                      <img src={DeleteIcon} alt="delete" />
                    </button>

                    <p>{file.name}</p>
                  </div>
                )}

                <div className="checkWrap">
                  <ui.input.BasicCheckbox
                    id="termsAgree"
                    checkText={
                      quarterLang ? (
                        <>
                          <Link to="/terms/privacy">개인정보 처리방침</Link>에
                          동의합니다.
                        </>
                      ) : (
                        <>
                          Please agree to the&nbsp;
                          <Link to="/terms/privacy">Privacy Policy</Link>.
                        </>
                      )
                    }
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    errorText={wording.check}
                  />
                </div>

                <div className="sendButton">
                  <ui.button.BasicButton title="Send" onClick={onContinue} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Contact;
