import { api } from "service";

export default function useInquiry() {
  const postInquiry = async (data, event) => {
    const response = await api.inquiry.postInquiry(JSON.stringify(data));
    if (response.success) event.success(response);
  };

  return {
    postInquiry,
  };
}
