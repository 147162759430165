import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid var(--gray01);

  .tabList {
    display: flex;
    justify-content: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 185px;
      height: 60px;
      font-size: 18px;
      font-weight: 600;
      color: var(--gray02);

      &.active {
        color: var(--key01);
      }
    }
  }

  @media (max-width: 1400px) {
    .tabList {
      a {
        width: calc(100vw * (185 / 1400));
        height: calc(100vw * (60 / 1400));
        font-size: calc(100vw * (18 / 1400));
      }
    }
  }

  @media (max-width: 1400px) {
    .tabList {
      flex-wrap: wrap;
      justify-content: flex-start;

      a {
        width: calc(100vw * (167 / 375));
        height: calc(100vw * (40 / 375));
        font-size: calc(100vw * (18 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
