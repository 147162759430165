import Styles from "./styles";
import { NavLink } from "react-router-dom";

function Navigation({ tabList }) {
  return (
    <Styles.Container>
      <div className="inner">
        <ul className="tabList">
          {tabList.map((tab, index) => (
            <li key={index}>
              <NavLink
                to={tab.link}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {tab.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default Navigation;
