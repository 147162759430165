import styled from "styled-components";

const Container = styled.div`
  .linkWrap {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;

    a {
      width: 310px;
      height: 185px;
      box-shadow: 10px 10px 10px 0px rgba(0, 142, 207, 0.05);
    }
  }

  .affiliatesImgWrap {
    position: relative;
    height: 872px;

    a {
      position: absolute;
      bottom: 31px;
      right: 30px;
      width: 243px;
      height: 106px;
    }

    img {
      object-fit: contain;
    }
  }

  .scrollGuide {
    display: none;
  }

  .mapImg {
    height: 641px;
  }

  @media (max-width: 1400px) {
    .linkWrap {
      gap: calc(100vw * (30 / 1400));
      margin-bottom: calc(100vw * (50 / 1400));

      a {
        width: calc(100vw * (310 / 1400));
        height: calc(100vw * (185 / 1400));
        box-shadow: calc(100vw * (10 / 1400)) calc(100vw * (10 / 1400))
          calc(100vw * (10 / 1400)) 0 rgba(0, 142, 207, 0.05);
      }
    }

    .affiliatesImgWrap {
      height: calc(100vw * (872 / 1400));

      a {
        bottom: calc(100vw * (31 / 1400));
        right: calc(100vw * (30 / 1400));
        width: calc(100vw * (243 / 1400));
        height: calc(100vw * (106 / 1400));
      }
    }

    .mapImg {
      height: calc(100vw * (641 / 1400));
    }
  }

  @media (max-width: 767px) {
    .linkWrap {
      gap: calc(100vw * (11 / 375));
      margin-bottom: calc(100vw * (20 / 375));

      a {
        width: calc(100vw * (162 / 375));
        height: calc(100vw * (172 / 375));
        box-shadow: calc(100vw * (10 / 375)) calc(100vw * (10 / 375))
          calc(100vw * (10 / 375)) 0 rgba(0, 142, 207, 0.05);
      }
    }

    .affiliatesImgWrap {
      height: calc(100vw * (1270 / 375));

      a {
        bottom: calc(100vw * (87 / 375));
        right: calc(100vw * (4 / 375));
        width: calc(100vw * (157 / 375));
        height: calc(100vw * (71 / 375));
      }

      img {
        object-fit: contain;
      }
    }

    .scrollGuide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vw * (37 / 375));
      gap: calc(100vw * (14 / 375));
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (14 / 375));
      font-weight: 500;
      background-color: var(--gray03);

      .icon {
        width: calc(100vw * (21 / 375));
        height: calc(100vw * (21 / 375));
      }
    }

    .mapWrap {
      width: 100%;
    }

    .mapImg {
      width: calc(100vw * (911 / 375));
      height: calc(100vw * (449 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
