import utils from "utils";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";

// img
import IconPrev from "resources/image/icon/icon_prev.svg";
import IconNext from "resources/image/icon/icon_next.svg";

function Pagination({ page, maxPage, list }) {
  const navigate = useNavigate();

  const onClickPrev = () => {
    if (page == 1) return;
    navigate(
      utils.etc.getQueryParamUrl({
        page: page - 1,
      })
    );
  };

  const onClickPage = (e) => {
    navigate(
      utils.etc.getQueryParamUrl({
        page: e.target.value,
      })
    );
  };

  const onClickNext = () => {
    if (page == maxPage) return;
    navigate(
      utils.etc.getQueryParamUrl({
        page: page + 1,
      })
    );
  };

  return (
    <Styles.Container>
      {list?.length > 1 && (
        <button type="button" className="prevButton" onClick={onClickPrev}>
          <img src={IconPrev} alt="Previous" />
        </button>
      )}

      <div className="paginationWrap">
        {list?.map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? "on" : ""}
              key={index}
              onClick={onClickPage}
            >
              {item}
            </button>
          );
        })}
      </div>

      {list?.length > 1 && (
        <button type="button" className="nextButton" onClick={onClickNext}>
          <img src={IconNext} alt="Next" />
        </button>
      )}
    </Styles.Container>
  );
}

export { Pagination };

export default Pagination;
