import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// images
import ImgTalent01 from "resources/image/recruitment/img_talent01.png";
import ImgTalent02 from "resources/image/recruitment/img_talent02.png";
import ImgTalent03 from "resources/image/recruitment/img_talent03.png";
import ImgTalent04 from "resources/image/recruitment/img_talent04.png";
import ImgTalent05 from "resources/image/recruitment/img_talent05.png";
import ImgTalent01Mb from "resources/image/recruitment/img_talent01_mb.png";
import ImgTalent02Mb from "resources/image/recruitment/img_talent02_mb.png";
import ImgTalent03Mb from "resources/image/recruitment/img_talent03_mb.png";
import ImgTalent04Mb from "resources/image/recruitment/img_talent04_mb.png";
import ImgTalent05Mb from "resources/image/recruitment/img_talent05_mb.png";
import IconInnovationCreativity from "resources/image/icon/icon_innovation_creativity.svg";
import IconProfessionalExcellence from "resources/image/icon/icon_professional_excellence.svg";
import IconTeamworkCollaboration from "resources/image/icon/icon_teamwork_collaboration.svg";
import IconEthicsResponsibility from "resources/image/icon/icon_ethics_responsibility.svg";

function Talent() {
  const { quarterLang } = useLanguage();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const list = [
    {
      img: ImgTalent01,
      mbImg: ImgTalent01Mb,
      title: quarterLang ? "도전 정신" : "Challenge Spirit",
      desc: quarterLang ? "Challenge Spirit" : "",
    },
    {
      img: ImgTalent02,
      mbImg: ImgTalent02Mb,
      title: quarterLang ? "혁신과 창의성" : "Innovation &\nCreativity",
      desc: quarterLang ? "Innovation &\nCreativity" : "",
    },
    {
      img: ImgTalent03,
      mbImg: ImgTalent03Mb,
      title: quarterLang ? "전문성" : "Professional\nExcellence",
      desc: quarterLang ? "Professional\nExcellence" : "",
    },
    {
      img: ImgTalent04,
      mbImg: ImgTalent04Mb,
      title: quarterLang ? "팀워크와 협력" : "Teamwork &\nCollaboration",
      desc: quarterLang ? "Teamwork &\nCollaboration" : "",
    },
    {
      img: ImgTalent05,
      mbImg: ImgTalent05Mb,
      title: quarterLang ? "윤리와 책임감" : "Ethics &\nResponsibility",
      desc: quarterLang ? "Ethics &\nResponsibility" : "",
    },
  ];

  const talentList = [
    {
      icon: IconInnovationCreativity,
      title: "Innovation & Creativity",
      desc: quarterLang ? (
        <>
          기존의 틀을 넘어서 새로운 길을 개척하는 창의적인 사고와 혁신적인 접근{" "}
          <br className="pcBr" />
          방식을 가진 인재를 찾고 있습니다. 우리의 연구는{" "}
          <br className="mbBr" /> 세계적인 기술을 바탕으로 <br />
          하고 있으며, 이를 뛰어넘어{" "}
          <b>
            새롭고 <br className="mbBr" /> 창의적인 솔루션을
            <br className="pcBr" /> 제시할 수 있는 인재
          </b>
          가 <br className="mbBr" /> 필요합니다.
        </>
      ) : (
        <>
          We are looking for talent <br className="pcBr" /> with creative
          <br className="mbBr" /> thinking and an innovative approach,
          <br /> breaking out of traditional frameworks
          <br className="mbBr" />
          to forge new paths. <br className="pcBr" /> Our research is{" "}
          <br className="mbBr" /> based on global technology,
          <br /> and we need{" "}
          <b>
            individuals who can go beyond
            <br className="pcBr" /> that to present new and
            <br className="mbBr" /> creative solutions.
          </b>
        </>
      ),
    },
    {
      icon: IconProfessionalExcellence,
      title: "Professional Excellence",
      desc: quarterLang ? (
        <>
          <b>
            전문적인 지식과 역량을 갖추고, 스스로 <br className="mbBr" />{" "}
            발전해나가는 인재
          </b>
          를 <br className="pcBr" />
          ABioTech는 소중히 여깁니다. 우리의 연구와 기술 개발이{" "}
          <br className="mbBr" /> 세계적인 수준을 <br className="pcBr" />
          유지하기 위해서는 각 분야에서 탁월한 성과를 내는 전문가들이
          필요합니다.
        </>
      ) : (
        <>
          ABioTech values individuals
          <br />
          <b>
            with professional knowledge and skills who are continuously growing.
          </b>{" "}
          To maintain our world-class level of research and technology
          development, we need experts who can achieve exceptional results in
          their respective fields.
        </>
      ),
    },
    {
      icon: IconTeamworkCollaboration,
      title: "Teamwork & Collaboration",
      desc: quarterLang ? (
        <>
          다양한 배경을 가진 전문가들과 함께 <br className="mbBr" /> 협력하여
          <br className="pcBr" />
          <b>시너지를 창출할 수 있는 인재</b>를 원합니다.{" "}
          <br className="pcBr" />
          우리는 팀워크를 통해 더 큰 성과를 이루며,
          <br className="pcBr" />
          상호 존중과 배려를 <br className="mbBr" /> 바탕으로 함께 성장해
          나갑니다.
        </>
      ) : (
        <>
          We seek individuals
          <br className="pcBr" /> who can
          <br className="mbBr" /> collaborate with professionals
          <br /> from diverse backgrounds and
          <br className="mbBr" />
          <b>generate synergy.</b>
          <br /> Through teamwork, we achieve
          <br className="mbBr" /> greater results, growing together
          <br className="mbBr" /> based on mutual respect and
          <br className="mbBr" /> consideration.
        </>
      ),
    },
    {
      icon: IconEthicsResponsibility,
      title: "Ethics & Responsibility",
      desc: quarterLang ? (
        <>
          바이오 기술 분야에서 중요한 것은 <b>연구의 정확성과 책임감</b>입니다.{" "}
          <br className="pcBr" /> ABioTech는 윤리적인 책임을 다하며, 사회적
          가치를 고려한 <br className="pcBr" />
          연구와 개발을 이끌어갈 수 있는 인재를 찾고 있습니다.
        </>
      ) : (
        <>
          ABioTech values <b>the accuracy and responsibility of research.</b> We
          seek individuals who can lead research and development that considers
          social values and takes responsibility.
        </>
      ),
    },
  ];

  return (
    <Styles.Container className="articleWrap">
      <article className="talentArticle01">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <h2 className="articleTitleSub">
                  The Ideal Candiaates for ABioTech
                </h2>
                <h2 className="articleTitle">ABioTech이 찾는 인재상</h2>
              </>
            ) : (
              <h2 className="articleTitle">
                The Ideal Candidates for ABioTech
              </h2>
            )}
          </div>

          <ul className="talentList">
            {list.map((item, index) => (
              <li className="talentItem" key={"talentItem" + index}>
                <div className="textWrap">
                  <p className="talentTitle">{item.title}</p>
                  <p className="talentDesc">{item.desc}</p>
                </div>

                <div className="imgWrap">
                  <img
                    src={isMobile ? item.mbImg : item.img}
                    alt={item.title}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </article>

      <article className="talentArticle02">
        <div className="inner">
          <div className="articleTitleWrap">
            <h2 className="articleTitle">Challenge Spirit</h2>
            {quarterLang ? (
              <p>
                ABioTech는 불확실성과 난제에 기꺼이 <br className="mbBr" /> 맞서
                도전하는 인재를 원합니다. <br />
                우리는 <b>암 줄기세포 치료제 개발</b>이라는{" "}
                <br className="mbBr" /> 혁신적인 목표를 이루기 위해 끊임없는{" "}
                <br />
                도전과 열정을 가진 인재를 환영합니다.
              </p>
            ) : (
              <p>
                We seek individuals, willing to embrace
                <br className="mbBr" />
                uncertainty and tackle difficult challenges.
                <br /> We welcome passionate and persistent
                <br className="mbBr" />
                individuals who are committed
                <br /> to achieving our innovative goal of
                <br className="mbBr" />
                developing{" "}
                <b>
                  cancer stem cell
                  <br className="mbBr" />
                  therapies
                </b>
                .
              </p>
            )}
          </div>

          <ul className="talentList">
            {talentList.map((item, index) => (
              <li className="talentItem" key={"talentItem" + index}>
                <div className="textWrap">
                  <p className="talentTitle">{item.title}</p>
                  <p className="talentDesc">{item.desc}</p>
                </div>

                <div className="iconWrap">
                  <div className="icon">
                    <img src={item.icon} alt={item.title} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Talent;
