import Styles from "./styles";

function Privacy() {
  return (
    <Styles.Container>
      <div className="inner">
        <div className="updateDate">Last Updates : 2018.10.02</div>

        <div className="content">
          <p>
            '㈜에이바이오테크'는 이용자의 개인정보를 중요시하며, 「정보통신망
            이용촉진 및 정보보호에 관한 법률」, 「개인정보보호법」을 준수하기
            위하여 노력하고 있습니다.
            <br />
            ㈜에이바이오테크는 개인정보취급방침을 통하여 회사가 이용자로부터
            제공받은 개인정보를 어떠한 용도와 방식으로 이용하고 있으며,
            개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다. <br />
            본 방침은 2018년 10월 02일부터 시행되며, 이를 개정하는 경우 웹사이트
            공지사항(또는 개별공지)을 통하여 공지하겠습니다.
            <br />
            <br />
            <b>총 칙</b>
            <br />
            <br />
            “개인정보”란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는
            성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는
            정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와
            용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
            <br />
            ㈜에이바이오테크는 이용자의 개인정보보호를 매우 중요시하며,
            「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
            「개인정보보호법」 및 행정안전부가 제정한 "개인정보보호지침" 등을
            준수하고 있습니다. 정보가 어떠한 용도의 방식으로 이용되고 있으며
            개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.
            <br />
            ㈜에이바이오테크는 본 개인정보취급방침을 홈페이지의 첫 화면에
            공개함으로써 이용자께서 언제나 용이하게 보실 수 있도록 조치하고
            있습니다. ㈜에이바이오테크는 개인정보취급방침의 지속적인 개선을
            위하여 개인정보취급방침을 개정하는데 필요한 절차를 정하고 있습니다.
            <br />
            그리고 개인정보취급방침을 개정하는 경우 버전번호 등을 부여하여
            개정된 사항을 쉽게 알아볼 수 있도록 하고 있습니다.
            <br />
            <br />
            <b>제1조 수집하는 개인정보의 항목 및 수집방법</b>
            <br />
            <br />
            수집하는 개인정보의 항목
            <br />
            ㈜에이바이오테크는 이용자로부터 아래와 같은 개인정보를 수집하고
            있습니다.
            <br />
            <br />
            필수항목 <br />
            ※ 귀하께서는 필수항목 수집•이용에 대한 동의를 거부하실 수 있으나,
            이는 서비스 제공에 필수적으로 제공되어야 하는 정보이므로, 동의를
            거부하실 경우 서비스를 이용 하실 수 없습니다.
            <br />
            ① 사이트(www.abiotech.co.kr) 관리를 위해 필요한 이용자의 개인정보 :
            성명, 이메일 주소, 연락처
            <br />
            <br />
            <b>제2조 개인정보의 수집 및 이용목적</b>
            <br />
            <br />
            ㈜에이바이오테크는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
            <br />
            본인 확인, 불만 처리 등 민원처리, 고지사항 전달, 혜택 제공 및 안내
            <br />
            개인정보 취급 및 정보주체의 개인정보 열람, 정정, 삭제, 처리 정지
            요구 시 본인확인, 개인식별
            <br />
            <br />
            <b>제3조 개인정보의 보유 및 이용기간</b>
            <br />
            <br />
            원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
            지체 없이 파기합니다.
            <br />
            단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이
            관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우
            ㈜에이바이오테크는 해당 개인정보를 별도의 데이터베이스(DB)로
            옮기거나 보관장소를 달리하여 보존합니다.
            <br />① 인터넷 등 로그기록/이용자의 접속지 추적자료: 1년
            (통신비밀보호법)
            <br />② 그 외의 통신사실 확인자료: 1년 (통신비밀보호법)
            <br />③ 소비자의 불만 또는 분쟁처리에 관한 기록: 5년 (전자상거래
            등에서의 소비자보호에 관한 법률)
            <br />④ 표시/광고에 관한 기록: 1년 (전자상거래 등에서의 소비자보호에
            관한 법률)
            <br />
            <br />
            <b>제4조 개인정보의 파기 절차 및 파기방법</b>
            <br />
            <br />
            회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
            정보를 지체 없이 파기합니다.
            <br />
            파기절차 및 방법은 다음과 같습니다.
            <br />
            <br />
            ① 파기절차
            <br />
            이용자의 개인정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
            별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
            따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도
            DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의
            다른 목적으로 이용되지 않습니다.
            <br />
            <br />
            ② 파기방법
            <br />
            전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
            방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로
            분쇄하거나 소각을 통하여 파기합니다.
            <br />
            <br />
            <b>제5조 수집한 개인정보의 위탁</b>
            <br />
            <br />
            ㈜에이바이오테크는 서비스 이행을 위해 아래와 같이 개인정보 취급
            업무를 외부 전문업체에 위탁하여 처리하고 있습니다. 개인정보 취급
            위탁은 개별 서비스 별로 그 이행을 위해 필요한 경우에 한해 각
            위탁업체에 대해 이루어집니다.
            <br />
            <br />
            개인정보의 처리를 위탁하는 경우에는 개인정보 보호의 안전을 기하기
            위하여 개인정보보호 관련 지시엄수, 개인정보에 대한 비밀유지, 제3자
            제공의 금지 및 사고시의 책임부담, 위탁기간, 처리 종료 후의
            개인정보의 반환 또는 파기 등을 명확히 규정하고, 위탁업체가
            개인정보를 안전하게 처리하도록 감독합니다.
            <br />
            업체, 위탁하는 업무의 내용이 변경될 경우, 웹사이트 공지사항(또는
            서면•이메일•전화•SMS 등의 방법으로 개별공지)을 통하여
            알려드리겠습니다.
            <br />
            <br />
            <b>제6조 제3자에게의 개인정보 제공</b>
            <br />
            <br />
            ㈜에이바이오테크는 이용자의 개인정보를 원칙적으로 외부에 제공하지
            않습니다. 다만, 아래의 경우에는 예외로 합니다.
            <br />
            ① 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
            방법에 따라 수사기관의 요구가 있는 경우
            <br />
            ② 통계작성, 학술연구나 시장조사를 위해 특정 개인을 식별할 수 없는
            형태로 가공하여 제공하는 경우
            <br />
            ③ 이용자들이 사전에 동의한 경우
            <br />
            <br />
            <b>제7조 이용자의 권리와 그 행사방법</b>
            <br />
            <br />
            이를 위하여 홈페이지의 “개인정보변경”(또는 “회원정보수정” 등) 메뉴를
            이용하시거나, 대표전화(02-539-8688) 또는 개인정보 관리책임자에게
            서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
            언제든지 개인정보 열람/ 정정•삭제• 처리정지/ 동의 철회를 요청할 수
            있습니다.
            <br />
            <br />
            ㈜에이바이오테크는 다음에 해당하는 경우에는 개인정보의 전부 또는
            일부에 대하여 열람/ 정정•삭제를 거절할 수 있습니다.
            <br />- 법률에 따라 열람이 금지되거나 제한되는 경우
            <br />- 다른 사람의 생명•신체를 해할 우려가 있거나 다른 사람의
            재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
            <br />
            이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
            완료하기 전까지 당해 개인정보를 이용 또는 제3자에게 제공하지
            않습니다.
            <br />
            또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
            처리결과를 제3자에게 지체 없이 통지하겠습니다.
            <br />
            <br />
            ㈜에이바이오테크는 이용자 혹은 법정대리인의 요청에 의해 삭제/ 처리
            정지된 개인정보는 “제3조 개인정보의 보유 및 이용기간”에 명시된 바에
            따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
            있습니다.
            <br />
            <br />
            이용자의 개인정보를 최신의 상태로 정확하게 입력하여 주시기 바랍니다.
            이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자
            자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우
            회원자격이 상실될 수 있습니다.
            <br />
            <br />
            이용자는 개인정보를 보호 받을 권리와 함께 스스로를 보호하고 타인의
            정보를 침해하지 않을 의무도 가지고 있습니다. 만약 이 같은 책임을
            다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 「정보통신망
            이용촉진 및 정보보호 등에 관한 법률」 등에 의해 처벌 받을 수
            있습니다.
            <br />
            <br />
            <b>
              제8조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
            </b>
            <br />
            <br />
            ㈜에이바이오테크는 이용자의 정보를 수시로 저장하고 찾아내는
            ‘쿠키(cookie)’ 등을 운용하지 않습니다. 쿠키란 회사의 웹사이트를
            운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은
            텍스트 파일로, 이용자의 컴퓨터 하드디스크에 저장됩니다.
            <br />
            <br />
            (1) 쿠키 설정 거부 방법
            <br />
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹
            브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
            저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
            있습니다.
            <br />. 쿠키 설정 방법 (인터넷 익스플로러8.0을 사용하고 있는 경우)
            <br />
            「도구」메뉴에서「인터넷옵션」을 선택합니다.「개인정보 탭」을
            클릭합니다.
            <br />
            「설정」을 이용하여 본인에게 맞는 쿠키 허용 수준을 설정하시면
            됩니다.
            <br />. 받은 쿠키를 보는 방법 (익스플로러8.0을 사용하고 있는 경우)
            <br />
            「도구」 메뉴에서 「인터넷옵션」 을 선택합니다.
            <br />
            「일반」 탭을 클릭하여 ‘검색기록’ 의 「설정」 으로 들어가서,
            「파일보기」 를 통해 확인합니다.
            <br />. 쿠키 설정 거부 방법 (인터넷 익스플로어 8.0 사용하고 있는
            경우)
            <br />
            「도구」 메뉴에서 「인터넷옵션」 을 선택합니다. 「개인정보 탭」 을
            클릭합니다.
            <br />
            「설정」 을 이용하여 상위 레벨로 하여 “모든 쿠키 차단”으로
            설정하시면 됩니다.
            <br />
            <br />
            <b>제9조 기타 개인정보 취급에 관한 방침</b>
            <br />
            <br />
            (1) 개인정보 보호를 위한 기술적, 관리적 조치
            <br />
            <br />
            ㈜에이바이오테크는 이용자의 개인정보를 취급함에 있어 개인정보가
            분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
            다음과 같은 기술적, 관리적 조치를 강구하고 있습니다.
            <br />① 내부관리계획의 수립 및 시행
            <br />
            ㈜에이바이오테크는 개인정보의 안전한 처리를 위하여 내부관리계획을
            수립하고 시행하고 있습니다.
            <br />
            ㈜에이바이오테크는 사내 개인정보보호 전담기구 등을 통해
            개인정보보호조치의 이행사항 및 담당자의 준수여부를 확인하여 문제
            발견 시 즉시 시정 조치하도록 하고 있습니다.
            <br />
            <br />
            ② 접근통제장치의 설치 및 운영
            <br />
            ㈜에이바이오테크는 침입차단시스템을 이용하여 외부로부터 무단 접근을
            통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위하여 가능한
            모든 기술적 장치를 갖추려 노력하고 있습니다.
            <br />
            접속 기록의 위조, 변조 방지를 위한 조치
            <br />
            ㈜에이바이오테크는 개인정보취급시스템에 접속한 기록을 보관, 관리하고
            있으며, 접속 기록이 위조, 변조되지 않도록 보안기능을 사용하고
            있습니다.
            <br />
            <br />
            ③ 개인정보의 암호화
            <br />
            이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를
            암호화하거나 파일 잠금 기능(Lock)을 사용하여 저장, 관리되며, 중요한
            데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            <br />
            <br />
            ④ 취급 직원의 최소화 및 교육
            <br />
            ㈜에이바이오테크는 이용자의 개인정보에 대한 접근권한을 이용자를 직접
            상대로 하여 마케팅 업무를 수행하는 자, 개인정보관리책임자 및 담당자
            등 개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 취급이
            불가피한 자로 제한 하고 있습니다.
            <br />
            개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및
            개인정보 보호 의무 등에 관해 교육을 실시하고 있습니다.
            <br />
            입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에
            방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를
            감사하기 위한 내부절차를 마련하고 있습니다.
            <br />
            개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서
            철저하게 이뤄지고 있으며 입사 및 퇴사후 개인정보 사고에 대한 책임을
            명확화하고 있습니다.
            <br />
            <br />
            (2) 게시물 운영 방침
            <br />
            ㈜에이바이오테크는 이용자의 게시물을 소중하게 생각하며 변조, 훼손,
            삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는
            그렇지 아니합니다.
            <br />
            • 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는
            게시물
            <br />
            • 스팸(spam)성 게시물
            <br />
            • 동의 없는 타인의 신상공개 게시물
            <br />
            • 회사 또는 제3자의 지적재산권 등 권리를 침해하는 내용의 게시물
            <br />
            • 기타 게시판 주제와 다른 내용의 게시물
            <br />
            <br />
            ㈜에이바이오테크는 바람직한 게시판 문화를 활성화하기 위하여 동의
            없는 타인의 신상 공개 시 특정 부분을 삭제하거나 기호 등으로 수정하여
            게시할 수 있으며, 다른 주제의 게시판으로 이동 가능한 내용일 경우
            해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다.
            <br />
            그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.
            <br />
            근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게
            있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기
            어려우므로 정보 공개 전에 심사 숙고하시기 바랍니다.
            <br />
            <br />
            (3) 이메일 무단 수집 거부 방침
            <br />
            ㈜에이바이오테크는 예약문의, 공지사항에 게시된 이메일 주소가
            전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단
            수집되는 것을 거부합니다. 이를 위반 시 「정보통신망 이용촉진 및
            정보보호 등에 관한 법률」등에 의해 처벌 받을 수 있습니다.
            <br />
            <br />
            <b>제10조 개인정보 관리책임자 및 고객서비스 담당부서 등 안내</b>
            <br />
            <br />
            ㈜에이바이오테크는 이용자의 개인정보를 보호하고 개인정보와 관련한
            불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를
            지정하고 있습니다.
            <br />
            <br />
            (1) 개인정보 관리책임자
            <br />
            성명 : 관리자
            <br />
            메일계정 : admin@a-biotech.co.kr
            <br />
            <br />
            (2) 기타 기관
            <br />
            이용자는 ㈜에이바이오테크의 서비스를 이용하시며 발생하는 모든
            개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실
            수 있습니다. ㈜에이바이오테크는 이용자들의 신고사항에 대해 신속하게
            충분한 답변을 드릴 것입니다.
            <br />
            <br />
            기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
            문의하시기 바랍니다.
            <br />
            개인정보침해신고센터 (www.118.or.kr / 118)
            <br />
            대검찰청 인터넷범죄수사센터 (www.spo.go.kr / 02-3480-3600)
            <br />
            경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)
            <br />
            개인정보취급방침 시행 일자 : 2018년 10월 02일
          </p>
        </div>
      </div>
    </Styles.Container>
  );
}

export default Privacy;
