import button from "./button";
import Pagination from "./Pagination";
import input from "./input";

export default {
  button,
  Pagination,
  input,
};

export { button, Pagination, input };
