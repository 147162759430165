import styled from "styled-components";

const Container = styled.div`
  .open {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 44px;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    color: var(--white);
    background-color: var(--key01);
    border-radius: 5px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
  }

  .date {
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 600;
    color: var(--gray02);
  }

  .fileList {
    margin-bottom: 30px;

    .fileItem {
      display: flex;
      align-items: center;
      height: 55px;
      background-color: var(--gray03);

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .attached {
        width: 221px;
        font-weight: 600;
        color: var(--key01);
        text-align: center;
      }

      a {
        padding: 0 35px;
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: underline;
        text-underline-offset: 3px;
        overflow: hidden;
      }
    }
  }

  .editorWrap {
    margin-bottom: 80px;
  }

  .listButton {
    width: 250px;
    margin: 0 auto;
  }

  @media (max-width: 1400px) {
    .open {
      width: calc(100vw * (165 / 1400));
      height: calc(100vw * (44 / 1400));
      margin-bottom: calc(100vw * (20 / 1400));
      font-size: calc(100vw * (22 / 1400));
      border-radius: calc(100vw * (5 / 1400));
    }

    .title {
      margin-bottom: calc(100vw * (10 / 1400));
      font-size: calc(100vw * (30 / 1400));
    }

    .date {
      margin-bottom: calc(100vw * (50 / 1400));
      font-size: calc(100vw * (22 / 1400));
    }

    .fileList {
      margin-bottom: calc(100vw * (30 / 1400));

      .fileItem {
        height: calc(100vw * (55 / 1400));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1400));
        }

        .attached {
          width: calc(100vw * (221 / 1400));
        }

        a {
          padding: 0 calc(100vw * (35 / 1400));
          font-size: calc(100vw * (18 / 1400));
          text-underline-offset: calc(100vw * (3 / 1400));
        }
      }
    }

    .editorWrap {
      margin-bottom: calc(100vw * (80 / 1400));
    }

    .listButton {
      width: calc(100vw * (250 / 1400));
    }
  }

  @media (max-width: 767px) {
    .open {
      width: calc(100vw * (102 / 375));
      height: calc(100vw * (31 / 375));
      margin-bottom: calc(100vw * (10 / 375));
      font-size: calc(100vw * (18 / 375));
      border-radius: calc(100vw * (5 / 375));
    }

    .title {
      margin-bottom: calc(100vw * (10 / 375));
      font-size: calc(100vw * (22 / 375));
    }

    .date {
      margin-bottom: calc(100vw * (30 / 375));
      font-size: calc(100vw * (18 / 375));
    }

    .fileList {
      margin: calc(100vw * (-10 / 375)) 0 calc(100vw * (30 / 375));

      .fileItem {
        height: calc(100vw * (40 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 375));
        }

        .attached {
          width: calc(100vw * (99 / 375));
          font-size: calc(100vw * (16 / 375));
        }

        a {
          padding: 0 calc(100vw * (15 / 375));
          font-size: calc(100vw * (16 / 375));
          text-underline-offset: calc(100vw * (3 / 375));
        }
      }
    }

    .editorWrap {
      margin-bottom: calc(100vw * (60 / 375));
    }

    .listButton {
      width: calc(100vw * (221 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
