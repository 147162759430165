import styled from "styled-components";

const Container = styled.main`
  .articleWrap {
    padding: 80px 0 150px;
  }

  .updateDate {
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 600;
    color: var(--gray02);
  }

  .content {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
  }

  @media (max-width: 1400px) {
    .articleWrap {
      padding: calc(100vw * (80 / 1400)) 0 calc(100vw * (150 / 1400));
    }

    .updateDate {
      margin-bottom: calc(100vw * (60 / 1400));
      font-size: calc(100vw * (24 / 1400));
    }

    .content {
      font-size: calc(100vw * (18 / 1400));
    }
  }

  @media (max-width: 767px) {
    .articleWrap {
      padding: calc(100vw * (60 / 375)) 0 calc(100vw * (130 / 375));
    }

    .updateDate {
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));
    }

    .content {
      font-size: calc(100vw * (16 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
