//숫자 3자리수 콤마 코드
function numberWithCommas(x) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return "0";
  }
}

function getFilterStr(parameter) {
  let filter = new URLSearchParams(parameter).toString();
  return decodeURIComponent(filter);
}

function getSearchParam() {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        ) +
        '"}'
    );
  } catch (e) {
    return {};
  }
}

function getQueryParamUrl(obj, isClear, isParamOnly) {
  try {
    obj = obj ? obj : {};
    let searchParam = isClear ? {} : getSearchParam();
    for (let key in obj) {
      if (obj[key] == null) {
        delete searchParam[key];
        continue;
      }
      searchParam[key] = obj[key];
    }
    if (isParamOnly) {
      return getFilterStr(searchParam);
    }
    return `${window.location.pathname}?${getFilterStr(searchParam)}`;
  } catch (e) {
    return {};
  }
}

export { numberWithCommas, getFilterStr, getSearchParam, getQueryParamUrl };
export default {
  numberWithCommas,
  getFilterStr,
  getSearchParam,
  getQueryParamUrl,
};
