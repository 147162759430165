import styled from "styled-components";

const Container = styled.div`
  .articleTitleWrap {
    margin-bottom: 30px;
    font-weight: bold;
  }

  .pipelineArticle01 {
    .imgWrap {
      height: 402px;
    }
  }

  article:where(
      .pipelineArticle02,
      .pipelineArticle03,
      .pipelineArticle04,
      .pipelineArticle05
    ) {
    .imgWrap {
      height: 445px;
    }
  }
  .desc {
    margin-top: 20px;
    line-height: 1.4;
  }

  @media (max-width: 1400px) {
    .articleTitleWrap {
      margin-bottom: calc(100vw * (30 / 1400));
    }

    .pipelineArticle01 {
      .imgWrap {
        height: calc(100vw * (402 / 1400));
      }
    }

    article:where(
        .pipelineArticle02,
        .pipelineArticle03,
        .pipelineArticle04,
        .pipelineArticle05
      ) {
      .imgWrap {
        height: calc(100vw * (445 / 1400));
      }
    }

    .desc {
      margin-top: calc(100vw * (20 / 1400));
    }
  }

  @media (max-width: 767px) {
    .articleTitleWrap {
      margin-bottom: calc(100vw * (20 / 375));
    }

    .pipelineArticle01 {
      .imgWrap {
        height: calc(100vw * (927 / 375));
      }
    }

    .pipelineArticle02 {
      .imgWrap {
        height: calc(100vw * (451 / 375));
      }
    }

    .pipelineArticle03 {
      .imgWrap {
        height: calc(100vw * (508 / 375));
      }
    }

    .pipelineArticle04 {
      .imgWrap {
        height: calc(100vw * (479 / 375));
      }
    }

    .pipelineArticle05 {
      .imgWrap {
        height: calc(100vw * (701 / 375));
      }
    }

    .desc {
      margin-top: calc(100vw * (20 / 375));
      word-break: keep-all;
    }
  }
`;

export { Container };

export default {
  Container,
};
