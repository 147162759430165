import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Pages from "pages";
import {
  Greeting,
  History,
  Affiliates,
  Organization,
  Business,
  Partner,
  Directions,
} from "pages/IntroductionPage/components";
import { Technology, Pipeline } from "pages/ResearchPage/components";
import {
  Announcements,
  AnnouncementsDetail,
  Contact,
} from "pages/CustomerSupportPage/components";
import {
  Talent,
  RecruitmentList,
  RecruitmentDetail,
} from "pages/RecruitmentPage/components";
import { Privacy, ServiceUse } from "pages/TermsPage/components";

export default function CustomRoute() {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      {/* 기업소개 */}
      <Route path="/introduction" element={<Pages.IntroductionPage />}>
        <Route path="greeting" element={<Greeting />} />
        <Route path="history" element={<History />} />
        <Route path="affiliates" element={<Affiliates />} />
        <Route path="organization" element={<Organization />} />
        <Route path="business" element={<Business />} />
        <Route path="partner" element={<Partner />} />
        <Route path="Directions" element={<Directions />} />
      </Route>
      {/* 연구개발 */}
      <Route path="/research" element={<Pages.ResearchPage />}>
        <Route path="technology" element={<Technology />} />
        <Route path="pipeline" element={<Pipeline />} />
      </Route>
      {/* 고객지원 */}
      <Route path="/customer-support" element={<Pages.CustomerSupportPage />}>
        <Route path="announcements" element={<Announcements />} />
        <Route
          path="announcements/detail/:id"
          element={<AnnouncementsDetail />}
        />
        <Route path="contact" element={<Contact />} />
      </Route>
      {/* 인재채용 */}
      <Route path="/recruitment" element={<Pages.RecruitmentPage />}>
        <Route path="talent" element={<Talent />} />
        <Route path="recruitment-list" element={<RecruitmentList />} />
        <Route
          path="recruitment-list/detail/:id"
          element={<RecruitmentDetail />}
        />
      </Route>
      {/* 이용약관 */}
      <Route path="/terms" element={<Pages.TermsPage />}>
        <Route path="privacy" element={<Privacy />} />
        <Route path="service-use" element={<ServiceUse />} />
      </Route>
    </Routes>
  );
}
