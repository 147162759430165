import styled from "styled-components";

const Container = styled.div`
  .historyListWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 910px;
    margin: 0 auto;

    &::before {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: calc(100% - 10px);
      background-color: var(--gray02);
      content: "";
    }
  }

  .historyText {
    position: relative;
    padding-left: 70px;
    font-size: 36px;
    font-weight: 600;
    color: var(--key01);

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background: var(--gradient);
      border-radius: 10px;
      box-shadow: 0px 5px 20px 0px rgba(116, 227, 208, 0.8);
      content: "";
    }
  }

  .list {
    padding-left: 78px;
  }

  .historyItem {
    display: flex;
    gap: 10px;

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }

  .date {
    width: 179px;
    font-size: 36px;
    font-weight: 600;
  }

  .listWrap li {
    position: relative;
    padding-left: 20px;
    line-height: 1.6;

    &::before {
      position: absolute;
      left: 0;
      content: "∙";
    }
  }

  @media (max-width: 1400px) {
    .historyListWrap {
      gap: calc(100vw * (40 / 1400));
      width: calc(100vw * (910 / 1400));

      &::before {
        left: calc(100vw * (20 / 1400));
        height: calc(100% - calc(100vw * (10 / 1400)));
      }
    }

    .historyText {
      padding-left: calc(100vw * (70 / 1400));
      font-size: calc(100vw * (36 / 1400));

      &::before {
        width: calc(100vw * (40 / 1400));
        height: calc(100vw * (40 / 1400));
        border-radius: calc(100vw * (10 / 1400));
        box-shadow: 0 calc(100vw * (5 / 1400)) calc(100vw * (20 / 1400)) 0
          rgba(116, 227, 208, 0.8);
      }
    }

    .list {
      padding-left: calc(100vw * (78 / 1400));
    }

    .historyItem {
      gap: calc(100vw * (10 / 1400));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (60 / 1400));
      }
    }

    .date {
      width: calc(100vw * (179 / 1400));
      font-size: calc(100vw * (36 / 1400));
    }

    .listWrap li {
      padding-left: calc(100vw * (20 / 1400));
    }
  }

  @media (max-width: 767px) {
    .historyListWrap {
      gap: calc(100vw * (22 / 375));
      width: 100%;

      &::before {
        left: calc(100vw * (10 / 375));
        height: calc(100% - calc(100vw * (10 / 375)));
      }
    }

    .historyText {
      padding-left: calc(100vw * (50 / 375));
      font-size: calc(100vw * (24 / 375));

      &::before {
        width: calc(100vw * (20 / 375));
        height: calc(100vw * (20 / 375));
        border-radius: calc(100vw * (5 / 375));
        box-shadow: 0 calc(100vw * (5 / 375)) calc(100vw * (20 / 375)) 0
          rgba(116, 227, 208, 0.8);
      }
    }

    .list {
      padding-left: calc(100vw * (50 / 375));
    }

    .historyItem {
      flex-direction: column;
      gap: calc(100vw * (10 / 375));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 375));
      }
    }

    .date {
      width: auto;
      font-size: calc(100vw * (24 / 375));
    }

    .listWrap li {
      padding-left: calc(100vw * (10 / 375));
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
