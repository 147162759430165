import Styles from "./styles";
import { useMediaQuery } from "react-responsive";

function MainBanner({ img, mbImg, title, description }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <Styles.Container
      style={{
        backgroundImage: `url(${isMobile ? mbImg : img})`,
      }}
    >
      <div className="inner">
        <p className="bannerTitle">{title}</p>
        <p className="bannerDescription">{description}</p>
      </div>
    </Styles.Container>
  );
}

export default MainBanner;
