import styled from "styled-components";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 22px;
  font-weight: 600;
  color: var(--white);
  background-color: var(--key01);
  border-radius: 500px;

  @media (max-width: 1400px) {
    height: calc(100vw * (45 / 1400));
    font-size: calc(100vw * (22 / 1400));
    border-radius: calc(100vw * (500 / 1400));
  }

  @media (max-width: 767px) {
    height: calc(100vw * (40 / 375));
    font-size: calc(100vw * (18 / 375));
    border-radius: calc(100vw * (500 / 375));
  }
`;

export { Button };

export default {
  Button,
};
