import date from "./date";
import etc from "./etc";
import reqValid from "./reqValid";

export { date, etc, reqValid };

export default {
  date,
  etc,
  reqValid,
};
