import connector from "service/connector";

const postInquiry = async (data) => {
  return await connector.jsonConnector(`inquiry`, "POST", data);
};

export { postInquiry };

export default {
  postInquiry,
};
