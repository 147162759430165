import ui from "components/ui";
import Styles from "./styles";
import { useState } from "react";
import { useLanguage, usePopup } from "hooks";
import { IMAGEPATH } from "service/connector";

// image
import IconClose from "resources/image/icon/icon_close.svg";

function MainModal({ modalClose }) {
  const { suffix } = useLanguage();
  const popupInfo = usePopup({ popup: { category_info_id: 10, type: 10 } });
  const popupData = popupInfo.getPopup?.data?.list[0] || {};
  const [check, setCheck] = useState(false);

  return (
    popupData.id && (
      <Styles.Container>
        <a href={popupData.url} target="_blank">
          <div className="modalImg">
            <img
              src={IMAGEPATH + popupData.image}
              alt={popupData["title" + suffix]}
            />
          </div>
        </a>

        <div className="modalBottom">
          <ui.input.BasicCheckbox
            id={"close"}
            checkText={"Do not open for a day"}
            checked={check}
            onChange={(e) => {
              setCheck(e.target.checked);
            }}
          />

          <button
            type="button"
            className="closeButton"
            onClick={() => {
              if (check) popupInfo.hidePopup();
              modalClose();
            }}
          >
            <img src={IconClose} alt="close" />
          </button>
        </div>
      </Styles.Container>
    )
  );
}

export { MainModal };

export default MainModal;
