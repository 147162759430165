import styled from "styled-components";

// images
import BgTalent from "resources/image/recruitment/bg_talent.jpg";

const Container = styled.div`
  .talentArticle01 {
    .talentList {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 420px;
      background-color: var(--gray03);
      border-radius: 20px;
    }

    .talentItem {
      width: 243px;
      height: 350px;
      background-color: var(--white);
      border-radius: 5px;
      box-shadow: 0px 10px 20px 0px rgba(50, 71, 92, 0.1);
      overflow: hidden;
    }

    .textWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(100% - 218px);
      font-weight: 600;
      text-align: center;
    }

    .talentTitle {
      margin-bottom: 5px;
      font-size: 22px;
      white-space: pre-line;
    }

    .talentDesc {
      font-size: 16px;
      color: var(--gray02);
      white-space: pre-line;
    }

    .imgWrap {
      height: 218px;
    }
  }

  .talentArticle02 {
    .articleTitleWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 285px;
      margin-bottom: 0;
      color: var(--white);
      text-align: center;
      background: url(${BgTalent}) no-repeat center / cover;

      p {
        margin-top: 15px;
        font-weight: normal;
        line-height: 1.6;

        b {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }

    .talentItem {
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        .textWrap {
          text-align: right;
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .icon {
          margin-left: auto;
        }
      }

      & > div {
        width: 50%;
      }

      .textWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 285px;
        padding: 0 52px;
        background-color: var(--gray03);
      }

      .talentTitle {
        margin-bottom: 15px;
        font-size: 38px;
        font-weight: 600;
        line-height: 1.4;
      }

      .talentDesc {
        letter-spacing: -0.05em;
        line-height: 1.6;

        b {
          font-size: 22px;
          font-weight: 600;
        }
      }

      .iconWrap {
        padding: 0 70px;

        .icon {
          width: 180px;
          height: 180px;
          filter: drop-shadow(0px 0px 50px rgba(141, 191, 171, 0.6));
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .talentArticle01 {
      .talentList {
        gap: calc(100vw * (8 / 1400));
        height: calc(100vw * (420 / 1400));
        border-radius: calc(100vw * (20 / 1400));
      }

      .talentItem {
        width: calc(100vw * (243 / 1400));
        height: calc(100vw * (350 / 1400));
        border-radius: calc(100vw * (5 / 1400));
        box-shadow: 0 calc(100vw * (10 / 1400)) calc(100vw * (20 / 1400)) 0
          rgba(50, 71, 92, 0.1);
      }

      .textWrap {
        height: calc(100% - calc(100vw * (218 / 1400)));
      }

      .talentTitle {
        margin-bottom: calc(100vw * (5 / 1400));
        font-size: calc(100vw * (22 / 1400));
      }

      .talentDesc {
        font-size: calc(100vw * (16 / 1400));
      }

      .imgWrap {
        height: calc(100vw * (218 / 1400));
      }
    }

    .talentArticle02 {
      .articleTitleWrap {
        height: calc(100vw * (285 / 1400));

        p {
          margin-top: calc(100vw * (15 / 1400));

          b {
            font-size: calc(100vw * (22 / 1400));
          }
        }
      }

      .talentItem {
        .textWrap {
          height: calc(100vw * (285 / 1400));
          padding: 0 calc(100vw * (52 / 1400));
        }

        .talentTitle {
          margin-bottom: calc(100vw * (15 / 1400));
          font-size: calc(100vw * (38 / 1400));
        }

        .talentDesc {
          b {
            font-size: calc(100vw * (22 / 1400));
          }
        }

        .iconWrap {
          padding: 0 calc(100vw * (70 / 1400));

          .icon {
            width: calc(100vw * (180 / 1400));
            height: calc(100vw * (180 / 1400));
            filter: drop-shadow(
              0 0 calc(100vw * (50 / 1400)) rgba(141, 191, 171, 0.6)
            );
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .talentArticle01 {
      .talentList {
        flex-direction: column;
        gap: calc(100vw * (15 / 375));
        height: auto;
        padding: calc(100vw * (20 / 375)) calc(100vw * (19 / 375));
        border-radius: calc(100vw * (10 / 375));
      }

      .talentItem {
        display: flex;
        width: 100%;
        height: calc(100vw * (113 / 375));
        border-radius: calc(100vw * (5 / 375));
        box-shadow: 0 calc(100vw * (10 / 375)) calc(100vw * (20 / 375)) 0
          rgba(50, 71, 92, 0.1);
      }

      .textWrap {
        width: calc(100% - calc(100vw * (126 / 375)));
        height: 100%;
      }

      .talentTitle {
        margin-bottom: calc(100vw * (5 / 375));
        font-size: calc(100vw * (18 / 375));
      }

      .talentDesc {
        font-size: calc(100vw * (14 / 375));
        font-weight: 500;
      }

      .imgWrap {
        width: calc(100vw * (126 / 375));
        height: 100%;
      }
    }

    .talentArticle02 {
      .articleTitleWrap {
        height: calc(100vw * (272 / 375));

        p {
          margin-top: calc(100vw * (10 / 375));
          line-height: 1.4;

          b {
            font-size: calc(100vw * (20 / 375));
          }
        }
      }

      .talentItem {
        flex-direction: column;
        border-bottom: 1px solid var(--gray01);

        .textWrap {
          width: 100%;
          height: calc(100vw * (279 / 375));
          padding: 0 calc(100vw * (25 / 375));
        }

        .talentTitle {
          margin-bottom: calc(100vw * (10 / 375));
          font-size: calc(100vw * (24 / 375));
          letter-spacing: -0.05em;
        }

        .talentDesc {
          line-height: 1.4;
          word-break: keep-all;

          b {
            font-size: calc(100vw * (18 / 375));
          }
        }

        .iconWrap {
          display: none;
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
