export default function useLanguage() {
  const type = {
    KOR: "",
    ENG: "_en",
  };

  const setLanguage = (item) => sessionStorage.setItem("language", item);
  const suffix = type[sessionStorage.getItem("language")] || "";
  const quarterLang = suffix === "";

  return {
    setLanguage,
    suffix,
    quarterLang,
  };
}
