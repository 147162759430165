import { useLanguage } from "hooks";
import Styles from "./styles";
import { useMediaQuery } from "react-responsive";
import ScrollContainer from "react-indiana-drag-scroll";

// images
import affiliatesImg01 from "resources/image/introduction/img_affiliates01.png";
import affiliatesImg02 from "resources/image/introduction/img_affiliates02.png";
import affiliatesImg01Mb from "resources/image/introduction/img_affiliates01_mb.png";
import affiliatesImg02Mb from "resources/image/introduction/img_affiliates02_mb.png";
import affiliatesImg03 from "resources/image/introduction/img_affiliates03.png";
import affiliatesImg03Mb from "resources/image/introduction/img_affiliates03_mb.png";
import ImgMap from "resources/image/introduction/img_affiliates_map.jpg";
import IconHand from "resources/image/icon/icon_hand.svg";

function Affiliates() {
  const { quarterLang } = useLanguage();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Styles.Container className="articleWrap">
      <article className="affiliatesArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Affiliates</div>
                <h2 className="articleTitle">관계사 현황</h2>
              </>
            ) : (
              <h2 className="articleTitle">Affiliates</h2>
            )}
          </div>

          <div className="linkWrap">
            <a
              href="https://cdw-holding.com.hk/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={isMobile ? affiliatesImg01Mb : affiliatesImg01}
                alt={quarterLang ? "관계사 현황" : "Affiliates"}
              />
            </a>

            <a
              href="https://tomoike.com.hk/en/index.html"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={isMobile ? affiliatesImg02Mb : affiliatesImg02}
                alt={quarterLang ? "관계사 현황" : "Affiliates"}
              />
            </a>
          </div>

          <div className="affiliatesImgWrap">
            <img
              src={isMobile ? affiliatesImg03Mb : affiliatesImg03}
              alt={quarterLang ? "관계사 현황" : "Affiliates"}
            />
            <a
              href="https://en.gsp-e.com/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
        </div>
      </article>

      <article className="affiliatesMapArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Affiliates Map</div>
                <h2 className="articleTitle">관계사 지도</h2>
              </>
            ) : (
              <h2 className="articleTitle">Affiliates Map</h2>
            )}
          </div>

          <div className="scrollGuide">
            <div className="icon">
              <img src={IconHand} alt="IconHand" />
            </div>
            {quarterLang
              ? "좌우로 슬라이드 하여 확인이 가능합니다."
              : "You can slide left and right to view."}
          </div>

          <ScrollContainer className="mapWrap">
            <div className="mapImg">
              <img
                src={ImgMap}
                alt={quarterLang ? "관계사 지도" : "Affiliates Map"}
              />
            </div>
          </ScrollContainer>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Affiliates;
