// 정규식
const regex = {
  phone: /^(\d{3})-(\d{3,4})-(\d{4})$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  id: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
};

// 데이터 검사
const required = (value) => !value?.toString().trim().length;
const phone = (value) => !regex.phone.test(value);
const password = (value) => !regex.password.test(value);
const email = (value) => !regex.email.test(value);
const id = (value) => !regex.id.test(value);

// 상황에 따라 Formatting 출력을 바꿔주세요.
// 1. setValue((e) => ({ ...e, [name]: value }))
// 2. setValue(value)

// 사업자번호 Formatting
// 형식: 000-00-00000

function businessFormatting(setValue, object = true) {
  return (event) => {
    let { name, value } = event.target;
    value = value?.replace(/[^0-9]/gi, "");
    value = value?.replace(/-/gi, "");
    value =
      value?.substr(0, 3) +
      (value?.length > 3
        ? "-" +
          value?.substr(3, 2) +
          (value?.length > 5 ? "-" + value?.substr(5, 5) : "")
        : "");
    object ? setValue((e) => ({ ...e, [name]: value })) : setValue(value);
  };
}

// 현금영수증 카드번호 Formatting
// 형식: 0000-0000-0000-000000

function cashReceipt(value) {
  if (required(value)) return "";
  value = value?.replace(/[^0-9]/gi, "");
  value =
    value?.substr(0, 4) +
    (value?.length > 4 ? "-" + value?.substr(4, 4) : "") +
    (value?.length > 8 ? "-" + value?.substr(8, 4) : "") +
    (value?.length > 12 ? "-" + value?.substr(12, 6) : "");
  return value;
}

// 전화번호 Formatting
// 형식: 000-0000-0000

function phoneFormatting(setValue) {
  return (event) => {
    let { name, value } = event.target;
    value = value?.replace(/[^0-9]/gi, "");
    value = value?.replace(/-/gi, "");
    value =
      value?.substr(0, 3) +
      (value?.length > 3
        ? "-" +
          value?.substr(3, 4) +
          (value?.length > 7 ? "-" + value?.substr(7, 4) : "")
        : "");
    setValue((e) => ({ ...e, [name]: value }));
  };
}

// 디테일 전화번호 Formatting
// 형식:
//   > 7자리 - 000-0000
//   > 8자리 - 0000-0000
//   > 9자리 - 00-000-0000
//   > 10자리 - 02-0000-0000 / 000-000-0000
//   > 11자리 - 000-0000-0000
//   > 12자리 - 0000-0000-0000
//   > 그 외 - 그대로 노출

// 소수점 Formatting
// > value: 값
// > natural: 정수부
// > decimal: 소수부
// ex > (x, 2, 3) : x = 99.999
// ex > (x, 4, 1) : x = 9999.9

function decimal(value, natural = null, decimal = 0) {
  value = decimal
    ? value?.replace(/[^0-9.]/g, "")
    : value?.replace(/[^0-9]/g, "");
  const parts = value?.split(".");
  const naturalPart = parts[0] || "";
  const decimalPart = parts[1] || "";

  if (parts.length > 2) {
    value = parts[0] + "." + parts.slice(1).join("");
  }
  if (natural !== null && naturalPart.length > natural) {
    value = naturalPart.slice(0, natural);
  }
  if (decimalPart.length > decimal) {
    value = naturalPart + "." + decimalPart.slice(0, decimal);
  }
  return value;
}

// 카멜 -> 스네이크
// 스네이크 -> 카멜

function camelSnakeToggle(str) {
  let result = "";
  let isAfterUnderscore = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char === "_") {
      isAfterUnderscore = true;
    } else if (isAfterUnderscore) {
      result += char.toUpperCase();
      isAfterUnderscore = false;
    } else if (/[A-Z]/.test(char)) {
      result += `_${char.toLowerCase()}`;
    } else {
      result += char;
    }
  }
  return result;
}

// validate 배열처리
// parameter 이름을 배열로 묶어서 보내주세요

function validate(data, setErrors) {
  let success = true;
  data
    .filter((item) => item.essential)
    .forEach((item) => {
      const isValid = item.essential.some(({ condition, wording }) => {
        const error = condition(item.value) ? wording : "";
        setErrors((e) => ({ ...e, [item.name]: error }));
        return !!error;
      });
      isValid && (success = false);
    });
  return success;
}

export {
  required,
  phone,
  password,
  email,
  id,
  businessFormatting,
  cashReceipt,
  phoneFormatting,
  decimal,
  camelSnakeToggle,
  validate,
};

export default {
  required,
  phone,
  password,
  email,
  id,

  businessFormatting,
  cashReceipt,
  phoneFormatting,
  decimal,
  camelSnakeToggle,
  validate,
};
