import connector from "service/connector"
import utils from "utils";

// 팝업
const getPopup = async data => await connector.connectFetchController(
  "popup" + (data ? "?" + utils.etc.getFilterStr(data) : ""), "GET"
);

export {
  getPopup
};

export default {
  getPopup
}