import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import Styles from "./styles";

// images
import ImgTechnology01 from "resources/image/research/img_technology01.png";
import ImgTechnology01Mb from "resources/image/research/img_technology01_mb.png";
import ImgTechnology02 from "resources/image/research/img_technology02.png";
import ImgTechnology02Mb from "resources/image/research/img_technology02_mb.png";
import ImgTechnology03 from "resources/image/research/img_technology03.png";
import ImgTechnology03Mb from "resources/image/research/img_technology03_mb.png";
import ImgTechnology04 from "resources/image/research/img_technology04.png";
import ImgTechnology04Mb from "resources/image/research/img_technology04_mb.png";
import ImgTechnology05 from "resources/image/research/img_technology05.png";
import ImgTechnology05Mb from "resources/image/research/img_technology05_mb.png";
import ImgTechnology06 from "resources/image/research/img_technology06.png";
import ImgTechnology06Mb from "resources/image/research/img_technology06_mb.png";
import ImgTechnology07 from "resources/image/research/img_technology07.png";
import ImgTechnology07En from "resources/image/research/img_technology07_en.png";
import ImgTechnology07Mb from "resources/image/research/img_technology07_mb.png";
import ImgTechnology07EnMb from "resources/image/research/img_technology07_en_mb.png";
import ImgTechnology08 from "resources/image/research/img_technology08.png";
import ImgTechnology08En from "resources/image/research/img_technology08_en.png";
import ImgTechnology08Mb from "resources/image/research/img_technology08_mb.png";
import ImgTechnology08EnMb from "resources/image/research/img_technology08_en_mb.png";
import ImgTechnology09 from "resources/image/research/img_technology09.png";
import ImgTechnology09Mb from "resources/image/research/img_technology09_mb.png";

function Technology() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { quarterLang } = useLanguage();

  const list = [
    {
      title: "About Antibody",
      img: isMobile ? ImgTechnology01Mb : ImgTechnology01,
      content: quarterLang ? (
        <p className="desc">
          항체는 외부 항원의 침입으로부터 방어하기 위해 생성되는 면역반응의
          산물로, 박테리아나 바이러스 등과 같은 외부 항원을 인식하고 무력화하는
          역할을 합니다. <br className="pcBr" />
          항체는 제각기 다른 구조의 결합 부위를 가지고 있으며, 마치 자물쇠와
          열쇠 구조처럼 항원과 특이적으로 결합합니다.
        </p>
      ) : (
        <p className="desc">
          Antibodies are produced against antigens as the result of immune
          response to defend the host from the harmful effects of antigens such
          as bacteria, viruses etc. Each antibody has two antigen-binding sites,
          which bind to specific antigen in a key-lock manner.
        </p>
      ),
    },
    {
      title: "Single-Chain Variable Fragment (scFv)",
      img: isMobile ? ImgTechnology02Mb : ImgTechnology02,
      content: quarterLang ? (
        <p className="desc">
          scFv (single-chain variable fragment) 란, 항체 중 결합부위만을 유전자
          재조합하여 하나의 사슬로 발현시킨 융합 단백질입니다.{" "}
          <br className="pcBr" />
          재조합된 scFv는 원본의 항체가 지니고 있던 항원 특이적 결합력이
          유지됩니다.
        </p>
      ) : (
        <p className="desc">
          A single chain variable fragment (scFv) is a design of a fused protein
          of antigen binding site. A scFv molecule is considered to exert the
          specific binding affinity to antigen derived from the original
          antibody.
        </p>
      ),
    },
    {
      title: "Phage Display Library of scFv",
      img: isMobile ? ImgTechnology03Mb : ImgTechnology03,
      content: quarterLang ? (
        <p className="desc">
          Phagedisplay는 특정 단백질의 유전정보를 bacteriophage에 삽입하여 해당
          단백질이 phage 표면에 제시되도록 하는 분자생물학적 기법입니다.{" "}
          <br className="pcBr" />
          단백질-단백질 상호작용 또는 <br className="mbBr" />
          항원-항체 반응을 효과적으로 확인할 수 있습니다.
        </p>
      ) : (
        <p className="desc">
          Phage display is a technique that makes expression of a specific
          protein as the fusion with a coat protein of bacteriophage to display
          the protein on the surface of phage particle. For a scFv library, the
          specific protein is fused proteins of antibody. The recombinant phages
          displaying scFv will be subjected to Biopanning to assess the affinity
          to specific antigen.
        </p>
      ),
    },
    {
      title: "Biopanning",
      img: isMobile ? ImgTechnology04Mb : ImgTechnology04,
      content: quarterLang ? (
        <p className="desc">
          Biopanning은 Phage Display 기술의 핵심 과정으로, 표적 항원에 강하게
          결합하는 항체를 효율적으로 선별하는 첨단 기술입니다. <br />이
          과정에서는 다양한 항체를 발현하는 파지(phage)를 표적 항원과 반응시킨
          후, 세척을 통해 비 특이적으로 결합한 파지 항체를 제거합니다. <br />이
          과정을 2-3회 반복함으로써, 표적 항원에 높은 결합력을 가진 파지 항체를
          효과적으로 선별할 수 있습니다.
        </p>
      ) : (
        <p className="desc">
          Biopanning is a core procedure to screen target phages in the library
          selecting phage clones that bind to target antigens or ligands. In
          this process, phages expressing various scFv are assessed for the
          affinity to target antigen eliminating the phages without affinity. By
          repeating binding-washing process 2-3 times, phage clones with high
          binding affinity to target antigens can be effectively selected.
        </p>
      ),
    },
    {
      title: "BLI Assay",
      img: isMobile ? ImgTechnology05Mb : ImgTechnology05,
      content: quarterLang ? (
        <p className="desc">
          Biolayer interferometry는 생체 분자 간 상호 작용을 실시간으로 분석하는
          광학 기술입니다. <br />
          바이오센서 표면에서 반사된 백색광의 간섭 패턴 변화를 측정하여, 분자
          결합으로 인한 광학적 두께 변화를 감지합니다. <br />이 방법은 라벨링
          없이 분자 간 결합률(ka)과 해리율(kd)을 높은 정확도로 측정할 수
          있습니다.
        </p>
      ) : (
        <p className="desc">
          The Biolayer interferometry (BLI) is an optical technology that
          analyzes biomolecular interactions in real-time.
          <br />
          Interference patterns caused by the binding of analytes to the
          molecules fixed on the surface of the biosensor tips are detected as
          the phase shift of wavelength in real time. Without fluorescence or
          isotope labeling, molecular association and dissociation rates are
          measured with high accuracy.
        </p>
      ),
    },
    {
      title: "Workflow",
      img: isMobile ? ImgTechnology06Mb : ImgTechnology06,
      content: quarterLang ? (
        <ul className="desc">
          <li>다양성 높은 scFv항체 라이브러리 보유</li>
          <li>Biopanning 을 통한 고효율 항체 선별 </li>
          <li>
            선별된 scFv 항체 서열을 인간화 항체 기술을 통해 면역원성이 낮고
            안정성 높은 최적화된 치료용 항체 생산
          </li>
          <li>BLI assay를 통한 정밀한 항체-항원 결합 특성 분석</li>
        </ul>
      ) : (
        <ul className="desc">
          <li>Single chain antibody library with high diversity.</li>
          <li>
            High efficiency screening for candidate antibodies through
            biopanning procedure.
          </li>
          <li>
            Conversion of scFv into humanized IgG as the antibody with low
            immunogenicity & high stability.
          </li>
          <li>
            Highly reliable measurement of binding affinity through BLI assay.
          </li>
        </ul>
      ),
    },
    {
      title: "Library Features",
      img: quarterLang
        ? isMobile
          ? ImgTechnology07Mb
          : ImgTechnology07
        : isMobile
        ? ImgTechnology07EnMb
        : ImgTechnology07En,
      content: (
        <div className="subImgWrap">
          <img
            src={
              quarterLang
                ? isMobile
                  ? ImgTechnology08Mb
                  : ImgTechnology08
                : isMobile
                ? ImgTechnology08EnMb
                : ImgTechnology08En
            }
            alt="Library Features"
          />
        </div>
      ),
    },
    {
      title: "CDR Shuffling Technology",
      img: isMobile ? ImgTechnology09Mb : ImgTechnology09,
      content: quarterLang ? (
        <p className="desc">
          CDR Shuffling기술을 적용하여 항체 다양성과 친화도를 극대화한 고성능
          항체 라이브러리를 구축 및 보유하고 있습니다.
        </p>
      ) : (
        <p className="desc">
          High performance antibody library with maximized antibody diversity
          and affinity with CDR shuffling technology applied
        </p>
      ),
    },
  ];

  return (
    <Styles.Container className="articleWrap">
      {list.map((item, index) => (
        <article
          className={`technologyArticle0${index + 1}`}
          key={`technologyArticle${index + 1}`}
        >
          <div className="inner">
            <div className="articleTitleWrap">
              <h2 className="articleTitle">{item.title}</h2>
            </div>

            <div className="imgWrap">
              <img src={item.img} alt={item.title} />
            </div>

            {item.content}
          </div>
        </article>
      ))}
    </Styles.Container>
  );
}

export default Technology;
