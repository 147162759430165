import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { common, layout } from "components";
import { useLanguage } from "hooks";
import { useLocation } from "react-router-dom";

// images
import BgBanner from "resources/image/recruitment/bg_banner.jpg";
import BgBannerMb from "resources/image/recruitment/bg_banner_mb.jpg";

function RecruitmentPage() {
  const location = useLocation();
  const pathname = location.pathname;
  const { quarterLang } = useLanguage();

  const path = pathname.split("/")[3];

  const tabList = [
    {
      title: quarterLang ? "인재상" : "Ideal Candidates",
      link: "/recruitment/talent",
    },
    {
      title: quarterLang ? "인재 채용" : "Careers",
      link: "/recruitment/recruitment-list",
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.MainBanner
          img={BgBanner}
          mbImg={BgBannerMb}
          title={quarterLang ? "인재채용" : "Careers"}
          description={
            quarterLang ? (
              <>
                주식회사 에이바이오텍을 방문해 주셔서 감사합니다. <br />
                혁신적인 신약 개발과 바이오 연구를 함께할 전문 인재와 함께
                앞서가겠습니다.
              </>
            ) : (
              <>
                Thank you for visiting ABioTech Co., Ltd. <br />
                We strive to lead the way in innovative drug development and
                biotechnology research with a team of dedicated professionals.
              </>
            )
          }
        />
        {path !== "detail" && <layout.Navigation tabList={tabList} />}
        <Outlet />
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default RecruitmentPage;
