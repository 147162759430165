import connector from "service/connector";
import utils from "utils";

const getBoard = async (data) => {
  return await connector.connectFetchController(
    `board${data ? "?" + utils.etc.getFilterStr(data) : ""}`,
    "GET"
  );
};

const getBoardDetail = async (data) => {
  return await connector.connectFetchController(`board/${data}`, "GET");
};

const getBoardGroup = async () => {
  return await connector.connectFetchController(`board/group`, "GET");
};

export { getBoard, getBoardDetail, getBoardGroup };

export default {
  getBoard,
  getBoardDetail,
  getBoardGroup,
};
