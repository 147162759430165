import Styles from "./styles";

export default function BasicButton({ title, onClick }) {
  return (
    <div>
      <Styles.Button type="button" onClick={onClick}>
        {title}
      </Styles.Button>
    </div>
  );
}
