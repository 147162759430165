import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    font-size: 22px;
    font-weight: 600;
    color: var(--gray02);
    background-color: var(--gray03);
    border-radius: 5px;
  }

  & .prevButton,
  .nextButton {
    width: 46px;
  }

  & .paginationWrap {
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      width: 49px;

      &.on {
        color: #fff;
        background-color: var(--key01);
      }
    }
  }

  @media (max-width: 1400px) {
    gap: calc(100vw * (15 / 1400));

    button {
      height: calc(100vw * (46 / 1400));
      font-size: calc(100vw * (22 / 1400));
      border-radius: calc(100vw * (5 / 1400));
    }

    & .prevButton,
    .nextButton {
      width: calc(100vw * (46 / 1400));
    }

    & .paginationWrap {
      gap: calc(100vw * (5 / 1400));

      button {
        width: calc(100vw * (49 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    gap: calc(100vw * (5 / 375));

    button {
      height: calc(100vw * (41 / 375));
      font-size: calc(100vw * (22 / 375));
      border-radius: calc(100vw * (5 / 375));
    }

    & .prevButton,
    .nextButton {
      width: calc(100vw * (41 / 375));
    }

    & .paginationWrap {
      gap: calc(100vw * (5 / 375));

      button {
        width: calc(100vw * (44 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
