import styled from "styled-components";

const Container = styled.div`
  .newsList {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 11px;
  }

  .pagination {
    margin-top: 80px;
  }

  @media (max-width: 1400px) {
    .newsList {
      gap: calc(100vw * (50 / 1400)) calc(100vw * (11 / 1400));
    }

    .pagination {
      margin-top: calc(100vw * (80 / 1400));
    }
  }

  @media (max-width: 767px) {
    .newsList {
      flex-direction: column;
      gap: calc(100vw * (10 / 375));
    }

    .pagination {
      margin-top: calc(100vw * (60 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
