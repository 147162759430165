import connector from "service/connector";

const uploadFile = async (data) => {
  return await connector.connectFetchController(`upload/file`, "POST", data);
};

export { uploadFile };

export default {
  uploadFile,
};
