import { useLanguage } from "hooks";
import Styles from "./styles";
import { useMediaQuery } from "react-responsive";

// images
import ImgPartner from "resources/image/introduction/img_partner.png";
import ImgPartnerMb from "resources/image/introduction/img_partner_mb.png";

function Partner() {
  const { quarterLang } = useLanguage();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Styles.Container className="articleWrap">
      <article className="partnerArticle">
        <div className="inner">
          <div className="articleTitleWrap">
            {quarterLang ? (
              <>
                <div className="articleTitleSub">Business partner</div>
                <h2 className="articleTitle">파트너사</h2>
              </>
            ) : (
              <h2 className="articleTitle">Business Partner</h2>
            )}
          </div>

          <div className="partnerImgWrap">
            <img
              src={isMobile ? ImgPartnerMb : ImgPartner}
              alt={quarterLang ? "파트너사" : "Business Partner"}
            />
          </div>
        </div>
      </article>
    </Styles.Container>
  );
}

export default Partner;
