import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    background-color: #fff;
    border: 1px solid var(--black);
    border-radius: 5px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.05);
  }

  .modalTop {
    min-height: 147px;
    padding: 31px;

    .title {
      font-weight: bold;
    }

    .text {
      margin-top: 17px;
      font-size: 16px;
      font-weight: 500;
      color: var(--gray02);
      line-height: 1.4;
    }
  }

  .modalBottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding: 0 30px;
    border-top: 1px solid var(--black);

    button {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1400px) {
    .modalInner {
      width: calc(100vw * (460 / 1400));
      border-radius: calc(100vw * (5 / 1400));
      box-shadow: calc(100vw * (10 / 1400)) calc(100vw * (10 / 1400))
        calc(100vw * (10 / 1400)) 0 rgba(0, 0, 0, 0.05);
    }

    .modalTop {
      min-height: calc(100vw * (147 / 1400));
      padding: calc(100vw * (31 / 1400));

      .text {
        margin-top: calc(100vw * (17 / 1400));
        font-size: calc(100vw * (16 / 1400));
      }
    }

    .modalBottom {
      height: calc(100vw * (50 / 1400));
      padding: 0 calc(100vw * (30 / 1400));

      button {
        width: calc(100vw * (20 / 1400));
        height: calc(100vw * (20 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    .modalInner {
      width: calc(100vw * (326 / 375));
      border-radius: calc(100vw * (5 / 375));
      box-shadow: calc(100vw * (10 / 375)) calc(100vw * (10 / 375))
        calc(100vw * (10 / 375)) 0 rgba(0, 0, 0, 0.05);
    }

    .modalTop {
      min-height: calc(100vw * (127 / 375));
      padding: calc(100vw * (30 / 375));

      .text {
        margin-top: calc(100vw * (15 / 375));
        font-size: calc(100vw * (14 / 375));
      }
    }

    .modalBottom {
      height: calc(100vw * (37 / 375));
      padding: 0 calc(100vw * (15 / 375));

      button {
        width: calc(100vw * (20 / 375));
        height: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
