import styled from "styled-components";

const Container = styled.div`
  .title {
    width: 910px;
    margin: 0 auto 30px;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
  }

  .thumbnailWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 910px;
    margin: 0 auto;
    padding: 31px;
    font-size: 22px;
    font-weight: 600;
    color: var(--gray02);
    background-color: var(--gray03);
    border-radius: 5px;

    .img {
      width: 426px;
      height: 358px;
      border-radius: 5px;
      overflow: hidden;
    }

    .textWrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .official {
      margin-bottom: 10px;
    }

    .logo {
      width: 170px;
      height: 30px;
      margin-bottom: 40px;
    }
  }

  .fileList {
    margin-top: 20px;

    .fileItem {
      display: flex;
      align-items: center;
      height: 55px;
      background-color: var(--gray03);

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .attached {
        width: 221px;
        font-weight: 600;
        color: var(--key01);
        text-align: center;
      }

      a {
        padding: 0 35px;
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

  .editorWrap {
    margin: 50px 0 80px;
  }

  .listButton {
    width: 250px;
    margin: 0 auto;
  }

  @media (max-width: 1400px) {
    .title {
      width: calc(100vw * (910 / 1400));
      margin: 0 auto calc(100vw * (30 / 1400));
      font-size: calc(100vw * (30 / 1400));
    }

    .thumbnailWrap {
      width: calc(100vw * (910 / 1400));
      padding: calc(100vw * (31 / 1400));
      font-size: calc(100vw * (22 / 1400));
      border-radius: calc(100vw * (5 / 1400));

      .img {
        width: calc(100vw * (426 / 1400));
        height: calc(100vw * (358 / 1400));
        border-radius: calc(100vw * (5 / 1400));
      }

      .official {
        margin-bottom: calc(100vw * (10 / 1400));
      }

      .logo {
        width: calc(100vw * (170 / 1400));
        height: calc(100vw * (30 / 1400));
        margin-bottom: calc(100vw * (40 / 1400));
      }
    }

    .fileList {
      margin-top: calc(100vw * (20 / 1400));

      .fileItem {
        height: calc(100vw * (55 / 1400));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1400));
        }

        .attached {
          width: calc(100vw * (221 / 1400));
        }

        a {
          padding: 0 calc(100vw * (35 / 1400));
          font-size: calc(100vw * (18 / 1400));
          text-underline-offset: calc(100vw * (3 / 1400));
        }
      }
    }

    .editorWrap {
      margin: calc(100vw * (50 / 1400)) 0 calc(100vw * (80 / 1400));
    }

    .listButton {
      width: calc(100vw * (250 / 1400));
    }
  }

  @media (max-width: 767px) {
    .title {
      width: 100%;
      margin: 0 auto calc(100vw * (25 / 375));
      font-size: calc(100vw * (22 / 375));
    }

    .thumbnailWrap {
      flex-direction: column;
      gap: calc(100vw * (20 / 375));
      width: 100%;
      padding: calc(100vw * (20 / 375));
      font-size: calc(100vw * (18 / 375));
      border-radius: calc(100vw * (5 / 375));

      .img {
        width: 100%;
        height: calc(100vw * (247 / 375));
        border-radius: calc(100vw * (5 / 375));
      }

      .official {
        margin-bottom: calc(100vw * (5 / 375));
      }

      .logo {
        width: calc(100vw * (109 / 375));
        height: calc(100vw * (20 / 375));
        margin-bottom: calc(100vw * (30 / 375));
      }
    }

    .fileList {
      margin-top: calc(100vw * (20 / 375));

      .fileItem {
        height: calc(100vw * (40 / 375));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 375));
        }

        .attached {
          width: calc(100vw * (99 / 375));
          font-size: calc(100vw * (16 / 375));
        }

        a {
          padding: 0 calc(100vw * (15 / 375));
          font-size: calc(100vw * (16 / 375));
          font-weight: bold;
          text-underline-offset: calc(100vw * (3 / 375));
        }
      }
    }

    .editorWrap {
      margin: calc(100vw * (30 / 375)) 0 calc(100vw * (60 / 375));
    }

    .listButton {
      width: calc(100vw * (221 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
