import { useBoard, useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import { IMAGEPATH } from "service/connector";
import Styles from "./styles";

// images
import ImgPipeline01 from "resources/image/research/img_pipeline01.png";
import ImgPipeline02 from "resources/image/research/img_pipeline02.png";
import ImgPipeline02Mb from "resources/image/research/img_pipeline02_mb.png";
import ImgPipeline03 from "resources/image/research/img_pipeline03.png";
import ImgPipeline03Mb from "resources/image/research/img_pipeline03_mb.png";
import ImgPipeline04 from "resources/image/research/img_pipeline04.png";
import ImgPipeline04Mb from "resources/image/research/img_pipeline04_mb.png";
import ImgPipeline05 from "resources/image/research/img_pipeline05.png";
import ImgPipeline05En from "resources/image/research/img_pipeline05_en.png";
import ImgPipeline05Mb from "resources/image/research/img_pipeline05_mb.png";
import ImgPipeline05EnMb from "resources/image/research/img_pipeline05_en_mb.png";

function Pipeline() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { quarterLang } = useLanguage();
  const { board } = useBoard({
    board: {
      page: 1,
      type: 11,
      recordSize: 12,
    },
  });

  const list = [
    {
      title: "Pipeline",
      img:
        IMAGEPATH +
        (isMobile ? board.list?.[0]?.thumbnail : board.list?.[0]?.image),
    },
    {
      title: "Pipeline  ︱  Cripto-1",
      img: isMobile ? ImgPipeline02Mb : ImgPipeline02,
      content: quarterLang ? (
        <p className="desc">
          Cripto-1은 TGF-β 계열의 공동수용체로, 세포막에 GPI 앵커로 부착된
          단백질 이며, 세포 증식, 분화, 이동을 촉진하며, 배아 발달과 줄기세포
          유지에 중요한 역할을 합니다. <br />
          Cripto-1은 정상 성체 조직에서는 거의 발현되지 않지만, 다양한 암종에서
          과발현되어 종양 성장과 전이를 촉진합니다.
          <br />
          Cripto-1 항체는 이 항원에 특이적으로 결합하여 그 기능을 억제함으로써,
          암세포의 증식을 저해하고 전이를 감소시킬 수 있는 잠재력을 가집니다.{" "}
          <br className="pcBr" />
          따라서 Cripto-1 항체는 암 진단 및 표적 치료의 활용 가능성이 매우
          높습니다.
        </p>
      ) : (
        <p className="desc">
          Cripto-1 is a GPI anchored membrane protein serving as a coreceptor of
          Nodal, a member of TGF-β family.
          <br /> Cripto-1 has an important role in the development of embryos
          and postnatal mammary glands via promoting cell proliferation,
          differentiation and migration.
          <br /> Cripto-1 is barely expressed in normal adult tissues but
          overexpressed in many kinds of cancer such as pancreatic, breast and
          prostate cancer etc. Therefore, anti-Cripto-1 antibody is expected to
          suppress the proliferation and migration of cancer cells inhibiting
          the activity of Cripto-1.
          <br /> Cripto-1 has also a great potential as a target of cancer
          therapy with antibody-drug conjugates as well as a marker of
          diagnosis.
        </p>
      ),
    },
    {
      title: "Pipeline  ︱  VEGFR",
      img: isMobile ? ImgPipeline03Mb : ImgPipeline03,
      content: quarterLang ? (
        <p className="desc">
          VEGF와 VEGFR은 혈관 신생을 조절하는 <br className="mbBr" /> 중요한
          단백질과 수용체입니다. 혈관 신생은 정상적인 발달과 상처 치유에
          필수적입니다. <br />
          그러나 암이나 특정 눈 질환에서는 VEGF가 과다 생성되어 비정상적인
          혈관이 형성됩니다. VEGF와 VEGFR 항체 치료는 이 결합을 차단하여 혈관
          신생을 억제합니다.
          <br />
          이를 통해 암의 성장을 억제하고, 비정상적인 혈관 형성을 막아 황반변성
          및 당뇨망막병증 같은 질환을 치료하는 데 효과적입니다.
        </p>
      ) : (
        <p className="desc">
          Angiogenesis is an essential procedure for normal/tumor development
          and wound healing.
          <br /> The vascular endothelial growth factor (VEGF) regulates
          angiogenesis activating VEGF receptor (VEGFR).
          <br /> In some diseases such as solid cancer, macular degeneration and
          diabetic retinopathy, overexpression of VEGF is known to induce the
          formation of abnormal blood vessels.
          <br /> Hence, the antibodies blocking the interaction of VEGF and
          VEGFR are considered effective to treat these diseases.
        </p>
      ),
    },
    {
      title: "Pipeline  ︱  EGFR",
      img: isMobile ? ImgPipeline04Mb : ImgPipeline04,
      content: quarterLang ? (
        <p className="desc">
          EGFR 항체는 상피세포 성장인자 <br className="mbBr" /> 수용체(EGFR)를
          표적으로 하는 단백질입니다. <br />
          EGFR은 세포 성장과 분화를 조절하며, 여러 암에서 과발현되거나 변이가
          발생할 수 있습니다. <br />
          EGFR 항체는 다양한 암의 세포 성장을 억제하며, 특히 대장암과
          두경부암에서 효과적입니다.
        </p>
      ) : (
        <p className="desc">
          Epidermal growth factor recetor (EGFR) is a cell surface receptor that
          regulates cell growth and differentiation. <br />
          The overexpression of EGFR I well known in many cases of solid tumors.
          <br />
          Anti-EGFR antibodies that block EGFR signaling are considered to
          inhibit cell growth.
          <br /> Therefore, It is currently used as a powerful tool in the
          treatment for ovarian c, kidney and lung cancer.
        </p>
      ),
    },
    {
      title: "Pipeline  ︱  Lipid Nanoparticle",
      img: quarterLang
        ? isMobile
          ? ImgPipeline05Mb
          : ImgPipeline05
        : isMobile
        ? ImgPipeline05EnMb
        : ImgPipeline05En,
      content: quarterLang ? (
        <p className="desc">
          현재 Lipid Nanoparticle(LNP)의 수용성 보조제로 사용되는 PEG는 단량체,
          이량체로 사용할 경우 독성이 높으며, <br className="pcBr" />
          고분자 중합체가 아닐 경우 수용성이 낮아 보조제로서 활용 난이도가 매우
          높습니다.
          <br />
          또한 PEG를 사용한 LNP 매개 mRNA 백신은 독성을 야기할 수 있는 것으로
          알려져 있습니다.
          <br />
          당사는 이러한 문제를 극복하기 위해 분지형 다중 수산기 보호 올리고머인
          Lanfa를 개발하였습니다.
          <br className="pcBr" />
          Lanfa는 적은 공정으로도 높은 수율로 효율적인 합성이 가능한 특징을
          가지고 있어, PEG를 대체함으로써 <br className="pcBr" />
          안전성과 안정성이 보다 높은 백신용 LNP 개발을 목표로 하고 있습니다.
        </p>
      ) : (
        <p className="desc">
          Currently, Lipid Nanoparticles (LNPs) commonly use PEG (Polyethylene
          Glycol) as a solubilizing excipient.
          <br /> However, PEG is known to have high toxicity when used in
          monomer or dimer, and low solubility in small-sized polymer makes it
          challenging to use as an excipient.
          <br />
          Moreover, LNPs using PEG as a matrix for mRNA vaccines are reported to
          cause toxicity.
          <br /> To address these challenges, we have developed Lanfa, a
          branched multi-hydroxy polymer excipient.
          <br /> Lanfa offers high solubility and efficient synthesis in
          small-scale processes, making it a promising alternative to PEG.
          <br /> By replacing PEG, our goal is to develop vaccine-specific LNPs
          with enhanced safety and stability.
        </p>
      ),
    },
  ];

  return (
    <Styles.Container className="articleWrap">
      {list.map((item, index) => (
        <article
          className={`pipelineArticle0${index + 1}`}
          key={`pipelineArticle${index + 1}`}
        >
          <div className="inner">
            <div className="articleTitleWrap">
              <h2 className="articleTitle">{item.title}</h2>
            </div>

            <div className="imgWrap">
              <img src={item.img} alt={item.title} />
            </div>

            {item.content}
          </div>
        </article>
      ))}
    </Styles.Container>
  );
}

export default Pipeline;
