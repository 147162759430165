import styled from "styled-components";

const Container = styled.div`
  .introductionList {
    display: flex;
    gap: 5px;

    .img {
      height: 360px;
    }

    .title {
      margin: 15px 0 5px;
      font-size: 24px;
      font-weight: 600;
      color: var(--gray02);
    }

    .description {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.4;
    }
  }

  .serviceBranchList {
    display: flex;
    gap: 20px;

    .serviceBranchItem {
      flex: 1 0;
      height: 332px;
    }
  }

  .branchArticle .articleTitleWrap {
    text-align: center;
  }

  @media (max-width: 1400px) {
    .introductionList {
      gap: calc(100vw * (5 / 1400));

      .img {
        height: calc(100vw * (360 / 1400));
      }

      .title {
        margin: calc(100vw * (15 / 1400)) 0 calc(100vw * (5 / 1400));
        font-size: calc(100vw * (24 / 1400));
      }

      .description {
        font-size: calc(100vw * (18 / 1400));
      }
    }

    .serviceBranchList {
      gap: calc(100vw * (20 / 1400));

      .serviceBranchItem {
        height: calc(100vw * (332 / 1400));
      }
    }
  }

  @media (max-width: 767px) {
    .introductionList {
      flex-direction: column;
      gap: calc(100vw * (10 / 375));

      .img {
        height: calc(100vw * (280 / 375));
      }

      .title {
        margin: calc(100vw * (15 / 375)) 0 calc(100vw * (5 / 375));
        font-size: calc(100vw * (20 / 375));
      }

      .description {
        font-size: calc(100vw * (16 / 375));
      }
    }

    .serviceBranchList {
      flex-direction: column;
      gap: calc(100vw * (10 / 375));

      .serviceBranchItem {
        height: calc(100vw * (362 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
